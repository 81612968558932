import React, { memo, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import {
    Table,
    TableBody,
    TableHead,
    TableRow as _TableRow,
    TableCell,
    IconButton,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import selectiveMemoComparison from '@zert-packages/utils/selectiveMemoComparison';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { archiveOneElement, renameAsync } from '@zert-packages/actions/explorerActions';
import dialogCustom from '../common/dialogCustom';
import DeleteConfirmDialog from '../shared/DeleteConfirmDialog';
import EditNameDialog from '../common/dialogs/EditNameDialog';
import { useSnackbar } from 'notistack';
import {showSnackbarChangesSaved, showSnackbarError, showSnackbarLoaderWithMessage} from "../Snackbars";
import { useDispatch, useSelector } from 'react-redux';
import { SELECT_CHECKLIST_ITEM } from '@zert-packages/actions/coreReducers';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import { getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';

import CreateChecklistItem from './CreateChecklistItemDialog';
import NoItemsInList from "../common/NoItemsInList";
import {getTileStatus} from "../ElementTile/utils/getTileStatus";
import ZertLoaderCenter from "../shared/ZertLoaderCenter";


const TableRow = withStyles({
    root: { cursor: 'pointer' }
})(_TableRow);

const NoName = <FormattedMessage id="Administration.RiskRowTemplateOverview.Table.NoName" defaultMessage="No name" />;

function TemplatesOverview({ fetchTemplates, templateName, initTemplate, customAdd, disableOpen = false }) {
    const dispatch = useDispatch();
    const { selectedChecklistItem } = useSelector((state) => state);

    const selectChecklistItem = (itemId) => {
        dispatch({
            type: SELECT_CHECKLIST_ITEM,
            payload: { itemId }
        });
    };

    const [list, setList] = useState(null);
    const history = useHistory();
    const snackbar = useSnackbar();
    const [selectedItem, setSelectedItem] = useState(selectedChecklistItem);
    const handleSelectItem = (event, id, row, columnId) => {
        if (event != null && event.ctrlKey) {
            window.open(getPluginByMimeType(row.mimeType).openpath.replace(':versionId', row.versionId), '_blank');
            setSelectedItem(id);
            selectChecklistItem(id);
        } else if (id == selectedItem && row && row.mimeType && (columnId == 'name' || columnId == 'id')) {
            history.push(getPluginByMimeType(row.mimeType).openpath.replace(':versionId', row.versionId));
        } else {
            setSelectedItem(id);
            selectChecklistItem(id);
        }
    };

    const handleEdit = (id) => () => {
        const foundItem = list.find((item) => item.id === id);

        const changeItemName = (newName) => {
            const snackbarId = showSnackbarLoaderWithMessage(
                snackbar,
                <FormattedMessage id="HoverWithLoader.SnackbarMessage" defaultMessage="Loading..." />
            );
            renameAsync(id, newName)
                .then(() => {
                    setList((prev) =>
                        prev.map((item) => {
                            if (item.id === foundItem.id) {
                                return {
                                    ...item,
                                    name: newName
                                };
                            }
                            return item;
                        })
                    );
                    snackbar.closeSnackbar(snackbarId);
                    showSnackbarChangesSaved(snackbar);
                })
                .catch((error) => showSnackbarError(snackbar, error.message));
        };
        if (foundItem !== undefined) {
            dialogCustom(EditNameDialog, {
                name: foundItem.name,
                onSave: changeItemName
            });
        }
        handleSelectItem(null, id);
        selectChecklistItem(id);
    };

    const handleAdd = (event) => {
        event.stopPropagation();
        if (initTemplate) {
            dialogCustom(customAdd || CreateChecklistItem, {
                onCreate: (item) => {
                    const snackbarId = showSnackbarLoaderWithMessage(
                        snackbar,
                        <FormattedMessage id="HoverWithCreating.SnackbarMessage" defaultMessage="Creating..." />
                    );
                    initTemplate({
                        ...item
                    })
                        .then((result) => {
                            setList((prev) => [...prev, result]);
                            snackbar.closeSnackbar(snackbarId);
                        })
                        .catch((error) => showSnackbarError(snackbar, error.message));
                }
            });
        }
    };

    const handleDelete = (id) => () => {
        dialogCustom(DeleteConfirmDialog, {
            onDelete: () => {
                const snackbarId = showSnackbarLoaderWithMessage(
                    snackbar,
                    <FormattedMessage id="HoverWithLoader.SnackbarMessage" defaultMessage="Loading..." />
                );
                setList((prev) => prev.filter((item) => item.id !== id));
                archiveOneElement(id).then(() => {
                    snackbar.closeSnackbar(snackbarId);
                    showSnackbarChangesSaved(snackbar);
                });
            }
        });
    };

    const compareFunc = (a, b) => {
        // console.log(a);
        const aName = a.id ? a.name : a.info.name;
        const bName = b.id ? b.name : b.info.name;
        if (typeof aName !== 'string' || !aName) return 1;
        if (typeof bName !== 'string' || !bName) return -1;
        return aName.localeCompare(bName, 'sv', { sensitivity: 'accent' });
    };

    useEffect(() => {
        fetchTemplates()
            .then((r) => {
                r.sort(compareFunc);
                setList(r);
            })
            .catch(() => setList([]));
    }, []);

    const openRiskRowTemplate = (row) => () => {
        window.open(getPluginByMimeType(row.mimeType).openpath.replace(':versionId', row?.versionId), '_blank');
        // history.push(getPluginByMimeType(row.mimeType).openpath.replace(':versionId', row.versionId));
    };

    const isSelected = (row) => {
        if (!row.info) {
            return row.id == selectedItem;
        }

        return row.info.id == selectedItem;
    };

    if (list === null) return <ZertLoaderCenter />;
    if (list.length === 0)
        return (
            <ExpansionContainer title={templateName} onAdd={handleAdd}>
                <NoItemsInList />
            </ExpansionContainer>
        );
    /*  const onClick = (event) => {
      history.push(getPluginByMimeType(row.mimeType).openpath.replace(':versionId', row.versionId));
    } */
    console.log('list', list)
    return (
        <ExpansionContainer title={templateName} onAdd={handleAdd}>
            <div style={{ padding: 10, width: '100%' }}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '100%' }}>
                                <FormattedMessage id="Administration.TemplatesOverview.Table.Name" defaultMessage="Name" />
                            </TableCell>
                            <TableCell>
                                <FormattedMessage id="Administration.TemplatesOverview.Table.ID" defaultMessage="ID" />
                            </TableCell>
                            <TableCell>
                                <FormattedMessage id="Administration.TemplatesOverview.Table.Version" defaultMessage="Version" />
                            </TableCell>
                            <TableCell>
                                <FormattedMessage id="Administration.TemplatesOverview.Table.Status" defaultMessage="Status" />
                            </TableCell>
                            <TableCell>
                                <FormattedMessage id="Administration.TemplatesOverview.Table.Open" defaultMessage="Open" />
                            </TableCell>
                            <TableCell>
                                <FormattedMessage id="Administration.TemplatesOverview.Table.Edit" defaultMessage="Edit" />
                            </TableCell>
                            <TableCell>
                                <FormattedMessage id="Administration.TemplatesOverview.Table.Delete" defaultMessage="Delete" />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map((row) => {
                            return (
                                <RiskRowTemplateRow
                                    key={row.id ? row.id : row.info.id}
                                    selected={isSelected(row)}
                                    onClick={(columnId) => (event) =>
                                        handleSelectItem(event, row.id ? row.id : row.info.id, row, columnId)}
                                    id={row.id ? row.id : row.info.id}
                                    label={row.id ? row.label : row.info.label}
                                    disableOpen={disableOpen}
                                    status={getTileStatus(row.id ? row : row.info)}
                                    onDelete={handleDelete(row.id ? row.id : row.info.id)}
                                    onOpen={openRiskRowTemplate(row.id ? row : row.info)} // openRiskRowTemplate(row)}
                                    onEdit={handleEdit(row.id ? row.id : row.info.id)}
                                    name={(row.id ? row.name : row.info.name) || NoName}
                                />
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
        </ExpansionContainer>
    );
}

export default TemplatesOverview;

const RiskRowTemplateRow = memo(
    ({ onClick, id, label, status, name, onEdit, onDelete, onOpen, selected, disableOpen }) => (
        <TableRow hover selected={selected}>
            <TableCell onClick={onClick('name')}>{name}</TableCell>
            <TableCell onClick={onClick('id')}>{id}</TableCell>
            <TableCell onClick={onClick('label')}>{label}</TableCell>
            <TableCell onClick={onClick('status')}>{status}</TableCell>
            <TableCell onClick={onClick('open')}>
                {!disableOpen && (
                    <IconButton size="small" onClick={onOpen}>
                        <OpenInNewIcon />
                    </IconButton>
                )}
            </TableCell>
            <TableCell onClick={onClick('edit')}>
                <IconButton size="small" onClick={onEdit}>
                    <EditIcon />
                </IconButton>
            </TableCell>
            <TableCell onClick={onClick('delete')}>
                <IconButton size="small" onClick={onDelete}>
                    <DeleteIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    ),
    selectiveMemoComparison(
        (p) => p.id,
        (p) => p.name,
        (p) => p.selected
    )
);

const useStyles = makeStyles({
    root: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'auto',
        margin: '10px',
        justifyContent: 'stretch'
    },
    summaryContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        alignItems: 'center',
        gridGap: '20px'
    },
    tableContainer: {
        overflowY: 'scroll',
        height: '500px',
        width: '100%'
    }
});

function ExpansionContainer({ title, onAdd, children }) {
    const classes = useStyles();
    return (
        <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className={classes.summaryContainer}>
                    <IconButton onClick={onAdd} size="small">
                        <AddIcon />
                    </IconButton>
                    <Typography variant="h6">{title}</Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
}
