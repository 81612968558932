import React from 'react';
import { FormattedMessage } from 'react-intl';

export const wft = {
  value: 'workflow-templates',
  label: 'Workflow templates',
  templateName: <FormattedMessage id="workflow-templates" defaultMessage="Workflow templates" />,
  hasTemplates: true,
  templateMimeType: 'application/zert-workflowtemplate'
};

export const modules = [
  {
    value: 'risk-assessment',
    label: 'Risk Assessment',
    templateName: <FormattedMessage id="risk-assessment-templates" defaultMessage="Risk Assessment templates" />,
    name: <FormattedMessage id="risk-assessment" defaultMessage="Risk Assessment" />,
    showMatrixes: true,
    hasTemplates: true,
    templateMimeType: 'application/zert-risk-row-template',
    actions: [
      {
        actionIdentifier: 'read-risk-assessment',
        actionName: <FormattedMessage id="read-risk-assessment" defaultMessage="Read Risk Assessment" />
      },
      {
        actionIdentifier: 'create-risk-assessment',
        actionName: <FormattedMessage id="create-risk-assessment" defaultMessage="Create Risk Assessment" />
      },
      {
        actionIdentifier: 'edit-risk-assessment',
        actionName: <FormattedMessage id="edit-risk-assessment" defaultMessage="Edit Risk Assessment" />
      }
    ]
  },

  {
    value: 'project',
    label: 'Project',
    hasTemplates: true,
    templateName: <FormattedMessage id="project-templates" defaultMessage="Project Templates" />,
    name: <FormattedMessage id="project" defaultMessage="Project" />,
    actions: [
      {
        actionIdentifier: 'read-project',
        actionName: <FormattedMessage id="read-project" defaultMessage="Read Project" />
      },
      {
        actionIdentifier: 'create-project',
        actionName: <FormattedMessage id="create-project" defaultMessage="Create Project" />
      },
      {
        actionIdentifier: 'edit-project',
        actionName: <FormattedMessage id="edit-project" defaultMessage="Edit Project" />
      }
    ],
    templateMimeType: 'application/zert-project-template'
  },

  {
    value: 'demand-project',
    label: 'Demand ProjectPlugin',
    templateName: <FormattedMessage id="demand-project-templates" defaultMessage="Demand Project Templates" />,
    name: <FormattedMessage id="demand-project" defaultMessage="Demand Project" />,
    actions: [
      {
        actionIdentifier: 'read-projectplanning',
        actionName: <FormattedMessage id="read-demand-project" defaultMessage="Read Demand Project" />
      },
      {
        actionIdentifier: 'create-projectplanning',
        actionName: <FormattedMessage id="create-demand-project" defaultMessage="Create Demand Project" />
      },
      {
        actionIdentifier: 'edit-projectplanning',
        actionName: <FormattedMessage id="edit-demand-project" defaultMessage="Edit Demand Project" />
      }
    ]
  },
  {
    value: 'safety-inspection',
    label: 'Safety inspection',
    templateName: <FormattedMessage id="safety-inspection-templates" defaultMessage="Safety inspection templates" />,
    name: <FormattedMessage id="safety-inspection" defaultMessage="Safety Inspection" />,
    actions: [
      {
        actionIdentifier: 'read-safety-inspection',
        actionName: <FormattedMessage id="read-safety-inspection" defaultMessage="Read Safety Inspection" />
      },
      {
        actionIdentifier: 'create-safety-inspection',
        actionName: <FormattedMessage id="create-safety-inspection" defaultMessage="Create Safety Inspection" />
      },
      {
        actionIdentifier: 'edit-safety-inspection',
        actionName: <FormattedMessage id="edit-safety-inspection" defaultMessage="Edit Safety Inspection" />
      }
    ],
    hasTemplates: true,
    templateMimeType: 'application/zert-questionnaire-template'
  },

  {
    value: 'checklist',
    label: 'Checklist',
    allowEditTemplate: true,
    templateName: <FormattedMessage id="checklist-templates" defaultMessage="Checklist templates" />,
    name: <FormattedMessage id="checklist" defaultMessage="Checklist" />,
    actions: [
      {
        actionIdentifier: 'read-checklist',
        actionName: <FormattedMessage id="read-checklist" defaultMessage="Read Checklist" />
      },
      {
        actionIdentifier: 'create-checklist',
        actionName: <FormattedMessage id="create-checklist" defaultMessage="Create Checklist" />
      },
      {
        actionIdentifier: 'edit-checklist',
        actionName: <FormattedMessage id="edit-checklist" defaultMessage="Edit Checklist" />
      }
    ],
    hasTemplates: true,
    templateMimeType: 'application/zert-questionnaire-template'
  },

  {
    value: 'submit',
    label: 'Submit',
    templateName: <FormattedMessage id="submits-templates" defaultMessage="Submits templates" />,
    name: <FormattedMessage id="submits" defaultMessage="Submits" />,
    hasTemplates: true,
    templateMimeType: 'application/zert-deviation-template',
    actions: [
      {
        actionIdentifier: 'read-submit',
        actionName: <FormattedMessage id="read-submit" defaultMessage="Read Submit" />
      },
      {
        actionIdentifier: 'create-submit',
        actionName: <FormattedMessage id="create-submit" defaultMessage="Create Submit" />
      },
      {
        actionIdentifier: 'edit-submit',
        actionName: <FormattedMessage id="edit-submit" defaultMessage="Edit Submit" />
      }
    ]
  },

  {
    value: 'explorer',
    label: 'Explorer',
    name: <FormattedMessage id="explorer" defaultMessage="Folders" />,
    actions: [
      {
        actionIdentifier: 'showExplorer',
        actionName: <FormattedMessage id="read-explorer" defaultMessage="Show Folders" />
      }
    ]
  },
  {
    value: 'search',
    label: 'Search',
    name: <FormattedMessage id="search" defaultMessage="Search" />,
    actions: [
      {
        actionIdentifier: 'showSearch',
        actionName: <FormattedMessage id="read-search" defaultMessage="Show Search" />
      }
    ]
  },
  {
    value: 'catalog',
    label: 'Catalog',
    name: <FormattedMessage id="structure" defaultMessage="Structure" />,
    actions: [
      {
        actionIdentifier: 'showCatalog',
        actionName: <FormattedMessage id="read-catalog" defaultMessage="Show Structure" />
      },
      {
        actionIdentifier: 'edit-catalog',
        actionName: <FormattedMessage id="edit-catalog" defaultMessage="Edit Structure" />
      },
      {
        actionIdentifier: 'create-catalog',
        actionName: <FormattedMessage id="create-catalog" defaultMessage="Create Structure" />
      }
    ]
  },
  {
    value: 'administration',
    label: 'Administration',
    name: <FormattedMessage id="administration" defaultMessage="Administration" />,
    actions: [
      {
        actionIdentifier: 'showAdministration',
        actionName: <FormattedMessage id="manage-user-and-groups-administration" defaultMessage="Manage users and groups" />
      },
      {
        actionIdentifier: 'create-shortcuts',
        actionName: <FormattedMessage id="adminSettings.createShortcut" defaultMessage="Create shortcuts" />
      },
      {
        actionIdentifier: 'provisioning-management',
        actionName: <FormattedMessage id="adminSettings.manageProvisioning" defaultMessage="Manage provisioning settings" />
      },
      {
        actionIdentifier: 'archive',
        actionName: <FormattedMessage id="adminSettings.archive" defaultMessage="Archive/unarchive elements" />
      },
      {
        actionIdentifier: 'delete',
        actionName: <FormattedMessage id="adminSettings.delete" defaultMessage="Delete elements" />
      },
    ]
  },
  {
    value: 'document',
    label: 'Document',
    name: <FormattedMessage id="document" defaultMessage="Document" />,
    actions: [
      {
        actionIdentifier: 'read-document',
        actionName: <FormattedMessage id="read-document" defaultMessage="Read document" />
      },
      {
        actionIdentifier: 'create-document',
        actionName: <FormattedMessage id="create-document" defaultMessage="Create document" />
      },
      {
        actionIdentifier: 'edit-document',
        actionName: <FormattedMessage id="edit-document" defaultMessage="Edit document" />
      }
    ]
  },
  {
    value: 'template-administration',
    label: 'Template Administration',
    name: <FormattedMessage id="template-administration" defaultMessage="Template Administration" />,
    actions: [
      {
        actionIdentifier: 'showTemplateAdministration',
        actionName: <FormattedMessage id="read-template-administration" defaultMessage="Show Template Administration" />
      },
      {
        actionIdentifier: 'edit-risk-row-template',
        actionName: <FormattedMessage id="edit-risk-row-template" defaultMessage="Edit Risk Row Template" />
      },
      {
        actionIdentifier: 'edit-questionnaire-template',
        actionName: <FormattedMessage id="edit-questionnaire-template" defaultMessage="Edit Questionnaire Template" />
      },
      {
        actionIdentifier: 'edit-deviation-template',
        actionName: <FormattedMessage id="edit-deviation-template" defaultMessage="Edit Deviation Template" />
      }
    ]
  },
  {
    value: 'ped-object',
    label: 'PED Administration',
    name: <FormattedMessage id="ped-object" defaultMessage="PED Administration" />,
    actions: [
      {
        actionIdentifier: 'create-ped-object',
        actionName: <FormattedMessage id="create-ped-object" defaultMessage="Create PED Object" />
      },
      {
        actionIdentifier: 'edit-ped-object',
        actionName: <FormattedMessage id="edit-ped-object" defaultMessage="Edit PED Object" />
      },
      {
        actionIdentifier: 'read-ped-object',
        actionName: <FormattedMessage id="read-ped-object" defaultMessage="Read PED Object" />
      }
    ]
  }
];
