import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  collapsibleContainer: (props) => ({
    transition: 'max-height 0.3s ease-in-out, opacity 0.3s ease-in-out',
    visibility: props.isAnimating || props.isExpanded ? 'visible' : 'hidden',
    opacity: props.isAnimating || props.isExpanded ? 1 : 0,
    willChange: 'max-height, opacity'
  }),
  expanded: {
    maxHeight: '99999px'
  },
  collapsed: {
    maxHeight: 0,
    overflow: 'hidden'
  }
}));

function ExpandableComponent({ children, expanded }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const cl = useStyles({ isExpanded, isAnimating });

  useEffect(() => {
    if (!expanded) {
      setIsAnimating(true);
      setTimeout(() => {
        setIsExpanded(false);
        setIsAnimating(false);
      }, 300);
    } else {
      setIsExpanded(true);
      setIsAnimating(true);
      setTimeout(() => setIsAnimating(false), 300);
    }
  }, [expanded]);

  return <div className={`${cl.collapsibleContainer} ${isExpanded ? cl.expanded : cl.collapsed}`}>{children}</div>;
}

export default ExpandableComponent;
