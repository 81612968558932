import React, { useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, FormHelperText, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage, useIntl } from 'react-intl';
import useAuth from '../useAuth';
import useIsMountedRef from '../useIsMountedRef';
import { AzureAuthenticationButton } from '../../AzureAuthenticationButton';

const useStyles = makeStyles(() => ({
  root: {}
}));

function JWTLogin({ className, ...rest }) {
  const classes = useStyles();
  const { login, getLoginMethod } = useAuth();

  const isMountedRef = useIsMountedRef();
  const intl = useIntl();
  const [userName, setUserName] = useState(null);
  const [loginType, setLoginType] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [isMicrosoftUser, setMicrosoftUser] = useState(false);
  const formRef = useRef();

  const submitUsername = async (values, { setErrors, setStatus, setSubmitting }) => {
    if (!validateLoginInfo(values.email)) {
      setStatus({ success: false });
      setErrors({
        submit: intl.formatMessage({
          id: 'login.noEmail',
          defaultMessage: 'Enter your email'
        })
      });
      return;
    }
    setLoginType(null);
    const loginType = await getLoginMethod(values.email);
    if (loginType == null) {
      setStatus({ success: false });
      setErrors({
        submit: intl.formatMessage({
          id: 'login.failedToGetLoginType',
          defaultMessage: 'Something went wrong, try again'
        })
      });
      setUserName(null);
      setLoginType(null);
    } else {
      setUserName(values.email);
      setLoginType(loginType);
    }
  };

  const submitFunction = async (values, { setErrors, setStatus, setSubmitting }) => {
    if (!isMicrosoftUser) {
      if (!validateLoginInfo(userName)) {
        setUserName(null);
        setLoginType(null);
        return;
      } else if (!validateLoginInfo(values.password)) {
        setStatus({ success: false });
        setErrors({
          submit: intl.formatMessage({
            id: 'login.noPassword',
            defaultMessage: 'Enter your password'
          })
        });
        return;
      }
    }
    try {
      await login(userName, values.password, isMicrosoftUser ? { ...currentUser, accessToken } : null);
      setStatus({ success: true });
      setSubmitting(false);
      setMicrosoftUser(false);
    } catch (err) {
      setStatus({ success: false });
      setErrors({ submit: getErrorMessage(err) });
      setSubmitting(false);
      setMicrosoftUser(false);
      console.error(err.message);
    }
  };

  const onAuthenticated = async (userAccountInfo, accessToken) => {
    setCurrentUser(userAccountInfo);
    setAccessToken(accessToken);
    setMicrosoftUser(true);
  };

  useEffect(() => {
    if (isMicrosoftUser && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [isMicrosoftUser]);

  const validateLoginInfo = (info) => {
    return info != null && info.length > 0;
  };

  const getErrorMessage = (err) => {
    if (err.status && err.status === 401 && err.message === 'Login failed') {
      return intl.formatMessage({
        id: 'login.failed',
        defaultMessage: 'Wrong password or username.'
      });
    } else if (err.status && err.status === 401 && err.message === 'MICROSOFT_ERROR') {
      return intl.formatMessage({
        id: 'login.microsoftFail',
        defaultMessage:
          'Microsoft integration does not seem to be active for your company or user, contact your super user for more information.'
      });
    }
    return err.message;
  };

  return (
    <>
      {userName == null && (
        <Formik
          initialValues={{
            email: null,
            submit: null
          }}
          validationSchema={Yup.object().shape({})}
          onSubmit={submitUsername}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form noValidate onSubmit={handleSubmit} className={clsx(classes.root, className)} {...rest}>
              <TextField
                fullWidth
                autoFocus
                helperText={touched.email && errors.email}
                label={intl.formatMessage({ id: 'Login.View.Email', defaultMessage: 'Email' })}
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
                size="small"
              />
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Box mt={2}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  <b>
                    <FormattedMessage id="Login.Credentials.Next" defaultMessage="Next" />
                  </b>
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      )}
      {userName != null && loginType != null && (
        <Formik
          innerRef={formRef}
          initialValues={{
            password: null,
            submit: null
          }}
          validationSchema={Yup.object().shape({})}
          onSubmit={submitFunction}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form noValidate onSubmit={handleSubmit} className={clsx(classes.root, className)} {...rest}>
              {loginType === 'zert' && (
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label={intl.formatMessage({ id: 'Login.View.Password', defaultMessage: 'Password' })}
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  size="small"
                  variant="outlined"
                  autoFocus
                />
              )}

              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Box mt={2}>
                {loginType === 'zert' && (
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    <b>
                      <FormattedMessage id="Login.Credentials.Entrance" defaultMessage="Login" />
                    </b>
                  </Button>
                )}
                {loginType === 'entra' && <AzureAuthenticationButton onAuthenticated={onAuthenticated} />}
                <Button
                  onClick={() => {
                    setLoginType(null);
                    setUserName(null);
                  }}
                  style={{ marginTop: '8px' }}
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  <b>
                    <FormattedMessage id="Login.Credentials.Cancel" defaultMessage="Cancel" />
                  </b>
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      )}
    </>
  );
}

JWTLogin.propTypes = {
  className: PropTypes.string
};

export default JWTLogin;
