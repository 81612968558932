import Tooltip from '@mui/material/Tooltip';
import React, { useContext, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { findColorVersion } from '@zert-packages/utils/findColorVersion';
import { PanelContext } from '../InfoElementWrapper/InfoElementWrapper';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 2,
    margin: '1px 2px',
    height: 'Calc(100% - 2px)'
  }
}));

function BorderTypeColor({ el, context, name = '' }) {
  const cl = useStyles();
  const [color, setColor] = useState({ background: null, backgroundColor: null });
  const [checkedOutByOther, setCheckedOutByOther] = useState(false);
  const { myuser, myLanguage } = useSelector((state) => state);
  const { values } = useContext(context || PanelContext);
  const findSocket = values.socket?.length > 0 && values.socket.find((sock) => sock.elementEvent.elementId === el.id);

  useEffect(() => {
    setColor(findColorVersion(el, myuser, findSocket, myLanguage));
    setCheckedOutByOther(myuser && el && el.checkedOut && el.checkoutByUser && myuser.userName !== el.checkoutByUser);
  }, [el, myLanguage]);

  return (
    <Tooltip
      title={
        checkedOutByOther && (
          <FormattedMessage
            id="checked.out.by"
            defaultMessage="Checked out by {user}"
            values={{ user: el.checkoutByUser }}
          />
        )
      }
    >
      <div data-name={name} className={cl.root} style={color} />
    </Tooltip>
  );
}

export default BorderTypeColor;
