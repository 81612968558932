import {handlePost, handlePostResultAsString, handleQuery} from "@zert-packages/actions/api";


export const getPluginList = async (pluginName) => {
  return  handleQuery(`/${pluginName}/list`);
}

///init ?parentVersionId




export const initLayout = async (pluginName) => {
  return handleQuery(`/${pluginName}/init`);
}

export const storeLayout = async (pluginName, versionId, elementData) => {
  return handlePost(`/${pluginName}/store/${versionId}`, elementData);
}