import Tooltip from '@mui/material/Tooltip';
import EditableTypography from '@zert-packages/components/shared/FormElements/EditableTypography';
import { updateElement } from '@zert-packages/components/InfoElement/helpers/main';
import { ExpandCollapseIconButton } from '@zert-packages/components/shared/CustomButtons/CustomButtons';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Menu } from '@mui/material';
import Tree from '@zert-packages/components/InfoElement/Structure/Tree';
import { getApiTree, getApiTreeChild } from '@zert-packages/components/InfoElement/Structure/API';
import BlockIcon from '@zert-packages/muiadopticons/BlockIcon';
import { getPluginByMimeTypeIcon } from '@zert-packages/utils/getPluginByMimeType';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import LabelIcon from '@mui/icons-material/Label';
import InfoIcon from '@mui/icons-material/Info';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FlagIcon from '@mui/icons-material/Flag';
import LockIcon from '@mui/icons-material/Lock';
import useVersionManager from '@zert-packages/utils/useVersionManager';
import getTranslation from '@zert-packages/utils/getTranslation.old';
import { FormattedMessage } from 'react-intl';
import { getParentElementsCount } from 'clm/src/plugins/BlockPlugin/API';
import { useDispatch, useSelector } from 'react-redux';
import ToolBarEditor from './ToolBarEditor';
import FormatBlock from './FormatBlock';
import AvailableDialog from './dialog/AvailableDialog';
import SkeletonLoader from '../../SkeletonLoader';
import useStyles from './style/styleAccordionWrapper';
import OpenNewWindows from './OpenNewWindows';
import TableFormatDialog from './dialog/TableFormatDialog';
import { findSelectedBlock } from '../helpers/findSelectedBlock';

function AbstractWrapper({
  info,
  values,
  el,
  id,
  child,
  context,
  setValues,
  setBlock,
  blockClick,
  showSideBar,
  block,
  closeOnclick,
  treeItem,
  parentItemCreate,
  selectedRange,
  setSelectedRange,
  findBorder,
  blockItems,
  createBlock,
  saveOnlyItem,
  style = {},
  table
}) {
  // const select = context ? context : (el && values && values?.treeItem && (values?.treeItem?.identifier === el?.identifier));
  const select = saveOnlyItem || findSelectedBlock(context, values, el);
  const cl = useStyles({ blockClick });
  const { myLanguage, myUser, block: selectedHeaderItem } = useSelector((state) => state);
  const snackbar = useSnackbar();
  const dispatch = useDispatch();
  const [openTree, setOpenTree] = useState(false);
  const [selectedItem, setSelectedItem] = useState({
    part: false,
    parentCount: '1'
  });
  const [versionInfo] = useVersionManager();
  const type = info && info.mimeType.split('/').pop();
  const container = type === 'zert-block' || type === 'zert-table' || type === 'zert-image';

  const findShowToolBarEditor = () => {
    if (table) {
      return true;
    }
    return (select || showSideBar) && !selectedHeaderItem.selectedItem;
  };

  const getTree = async () => {
    if (!id) {
      return;
    }
    if (child) {
      const result = await getApiTreeChild(id);
      if (result) {
        return Object.values(result)
          .flat()
          .map((el) => ({
            ...el,
            elementInfo: el,
            parentsTree: true,
            identifier: el.id
          }));
      }
    }
    const result = await getApiTree(id);
    if (result) {
      return Object.values(result).flat();
    }
    return [];
  };

  const handleOpenPopUp = (e) => {
    e.stopPropagation();
    if (blockClick) {
      return;
    }
    setOpenTree(e.currentTarget);
    setSelectedItem((pre) => ({ ...pre, part: true }));
  };

  const handleClose = () => {
    setOpenTree(null);
    setSelectedItem((pre) => ({ ...pre, part: false }));
  };

  const closeSelectedBlock = (e) => {
    if (!setValues) {
      return;
    }
    if (select && setBlock && type === 'zert-block') {
      e.stopPropagation();
      setBlock((pre) => ({ ...pre, isCollapsed: !pre.isCollapsed }));
    }
    if (closeOnclick) {
      e.stopPropagation();
      if (setBlock) {
        setBlock((pre) => ({
          ...pre,
          child: null,
          dropBlockTable: false,
          addImgRedactor: null,
          expandedToolbar: false,
          expandedChild: false,
          selectedItemBlock: []
        }));
      }
      setValues((pre) => ({
        ...pre,
        investmentLevel: 0,
        closeAllBlockItems: true,
        treeItem: { ...pre.parentBlock, parentTreeItem: true, elementInfo: { ...pre.parentBlock?.info } },
        closeChild: false,
        dropBlockTable: false,
        severalSelectedTreeItem: null
      }));
    }
  };

  const handleAvailable = (e) => {
    if (select) {
      e.stopPropagation();
    }
    renderInExceptionRoot(AvailableDialog, { block, setBlock, parentItemCreate });
  };

  const handlePlaceholder = (e) => {
    if (select) {
      e.stopPropagation();
    }
    renderInExceptionRoot(TableFormatDialog, { block, setBlock, parentItemCreate });
  };

  const getParentCount = async () => {
    const res = await getParentElementsCount(id);
    if (res && typeof res === 'number') {
      setSelectedItem((pre) => ({ ...pre, parentCount: res }));
    }
  };

  useEffect(() => {
    if (id) {
      getParentCount();
    }
  }, [id]);

  if (!info) {
    return (
      <div style={{ marginBottom: -5 }}>
        <SkeletonLoader height={38} />
      </div>
    );
  }

  return (
    <div
      style={{
        background: select ? 'rgb(244, 248, 250)' : '',
        marginLeft: select ? '-12px' : '',
        paddingLeft: select ? '7px' : '',
        marginRight: select ? '-5px' : '',
        cursor: treeItem && closeOnclick ? 'pointer' : ''
      }}
    >
      <div className={cl.rootAbstract} style={{ padding: select ? '0px 7px' : '0px 2px', ...style }}>
        <div
          className={cl.wrapperInfo}
          style={{
            color: select || (block && block.isHoveredBlock) ? 'rgb(0, 0, 0)' : 'rgb(75, 75, 75)'
          }}
        >
          <div className={cl.wrapperAbstract}>
            <BlockIcon svg={getPluginByMimeTypeIcon(info.mimeType, true)} />
            <div style={{ width: 'min-content' }}>
              {type !== 'zert-phrase' ? (
                <EditableTypography
                  disabled={info.approvedAt !== null}
                  variant="body1"
                  color={select || (block && block.isHoveredBlock) ? 'rgb(0, 0, 0)' : 'rgb(75, 75, 75)'}
                  textOverflow="ellipsis"
                  fontSize="0.9rem"
                  padding="0"
                  onSave={(name) => updateElement(snackbar, dispatch, info, { name }, myLanguage.value)}
                >
                  {info.name}
                </EditableTypography>
              ) : (
                <div />
              )}
            </div>
            <div>{info.label}</div>
            {el?.localeFilter && el.localeFilter.length > 0 && <FlagIcon />}
            {typeof el?.itemComment === 'string' && (
              <Tooltip title={el.itemComment}>
                <LockIcon style={{ fontSize: '14px' }} />
              </Tooltip>
            )}
            {el?.languageSeparated && <InfoIcon style={{ fontSize: '14px', color: '#01A1C7' }} />}
            {el?.publishingFilters?.length > 0 && <FilterAltIcon style={{ fontSize: '14px' }} />}

            {(findBorder || el?.format?.label) && (
              <div className={cl.titleParagraph} onClick={handleAvailable}>
                {el?.format?.label ? (
                  getTranslation(el.format.label)
                ) : (
                  <FormattedMessage id="no.paragraph.format" defaultMessage="No paragraph format" />
                )}
              </div>
            )}

            {container && (
              <FormatBlock
                block={block}
                select={select}
                blockClick={blockClick}
                setBlock={setBlock}
                parentItemCreate={parentItemCreate}
              />
            )}

            <div style={{ display: 'flex', gap: 5 }}>
              <div className={cl.partText} onClick={(e) => handleOpenPopUp(e)}>
                <span style={{ color: selectedItem.part ? '#01A1C7' : '' }}>
                  <FormattedMessage
                    id="part.of"
                    defaultMessage="Part of: {value}"
                    values={{ value: selectedItem.parentCount }}
                  />
                </span>
              </div>
              <Menu
                anchorEl={openTree}
                open={Boolean(openTree)}
                onClose={() => handleClose()}
                classes={{
                  paper: cl.TreeRootPopup
                }}
              >
                <Tree getRootNode={() => getTree()} context={context} />
              </Menu>
            </div>
            {el?.label && <LabelIcon className={cl.labelHeaderIcon} />}
          </div>
          {select && container && blockItems && (
            <ExpandCollapseIconButton
              size="small"
              sx={{ padding: '2px' }}
              expanded={!block.isCollapsed}
              onClick={closeSelectedBlock}
            />
          )}
          {!select && blockItems && block.isHoveredBlock && (
            <div className={cl.wrapperOpenIcon}>
              <OpenNewWindows el={el} />
            </div>
          )}
        </div>
        <div style={{ display: findShowToolBarEditor() ? 'block' : 'none' }}>
          <ToolBarEditor
            createBlock={createBlock}
            blockItems={blockItems}
            selectedRange={selectedRange}
            setBlock={setBlock}
            parentItemCreate={parentItemCreate}
            block={block}
            context={context}
            el={el}
            saveOnlyItem={saveOnlyItem}
          />
        </div>
      </div>
    </div>
  );
}

export default AbstractWrapper;
