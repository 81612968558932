export function roleToNumber(role) {
  if ("consumer" === role) {
    return 0;
  } else if ("producer" === role) {
    return 1;
  } else if ("task-administrator" === role) {
    return 2;
  } else if ("administrator" === role) {
    return 3;
  }
  return 100;
}