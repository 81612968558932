import React from 'react'
import {getPluginList} from "../API";
import TemplatesOverview from "@zert-packages/components/TemplatesOverview/TemplatesOverview";
import createListItem from "../helpers/createListItem ";


const MetaDataList = () => {


    const fetchLayoutList =  () => {
        return  getPluginList('metadata')
    }

    const addListItem =  async (name) => {
        return  createListItem('metadata', name)
    }



    return(<div className="PersonalSettings__MainContainer">
        <TemplatesOverview
            templateName={'Meta data'}
            fetchTemplates={fetchLayoutList}
            initTemplate={addListItem}
        />
    </div>)
}

export default MetaDataList