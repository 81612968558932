import React, { useContext } from 'react';
import clsx from 'clsx';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { PanelContext } from '@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper';
import CircleIcon from '@mui/icons-material/Circle';
import styleAccordionWrapper from './components/style/styleAccordionWrapper';

function AddBlockItemIcons({
  block,
  setBlock,
  blockClick,
  style,
  index,
  isLastItem = false,
  type = 'expandedToolbar',
  addSaveBlock,
  handleBlock
}) {
  const cl = styleAccordionWrapper({ blockClick, isLastItem });
  const context = useContext(PanelContext);

  const isToolbarExpanded = (block.expandedToolbar && !isLastItem) || (block.expandedToolbarBottom && isLastItem);
  const isHovered = (block.isHoveredAdd && !isLastItem) || (block.isHoveredAddBottom && isLastItem);

  const handleExpanded = async (e) => {
    e.stopPropagation();

    if (context?.values.blockClickCreateItemsBlock && !block[type]) {
      return;
    }
    if (addSaveBlock) {
      await addSaveBlock();
    }
    if (context) {
      context.setValues((pre) => ({
        ...pre,
        blockClickCreateItemsBlock: false,
        action: {
          ...pre.action,
          indexElementCreate: index
        }
      }));
    }

    setBlock((pre) => ({
      ...pre,
      [type]: !pre[type],
      createType: null
    }));
  };

  return (
    <div
      style={{
        position: 'relative'
      }}
    >
      <div
        className={clsx({
          [cl.closeImgButton]: isToolbarExpanded,
          [cl.addImgButton]: isHovered,
          [cl.addImgButtonPlaceholder]: !isToolbarExpanded && !isHovered
        })}
        onMouseEnter={() => !isToolbarExpanded && handleBlock(true, isLastItem ? 'isHoveredAddBottom' : 'isHoveredAdd')}
        onMouseLeave={() =>
          !isToolbarExpanded && handleBlock(false, isLastItem ? 'isHoveredAddBottom' : 'isHoveredAdd')
        }
      >
        {block[type] ? (
          <div style={style}>
            <CloseIcon onClick={(e) => handleExpanded(e)} className={cl.removeIcon} />
          </div>
        ) : isHovered ? (
          <div
            style={style}
            onClick={(e) => {
              handleExpanded(e);
              handleBlock(false, isLastItem ? 'isHoveredAddBottom' : 'isHoveredAdd');
            }}
          >
            <AddIcon />
          </div>
        ) : (
          <div style={style}>
            <CircleIcon style={{ fontSize: '6px' }} />
          </div>
        )}
      </div>
    </div>
  );
}

export default AddBlockItemIcons;
