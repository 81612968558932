import React, { useContext, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { AccordionSummary, Checkbox, FormControlLabel, RadioGroup, Radio } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import SelectedLanguagePopup from "@zert-packages/components/InfoElement/SelectedLanguagePopup";
import translateable from "../image/translateable-icon-colored.png";
import { useSelector } from "react-redux";
import Tree from "@zert-packages/components/InfoElement/Structure/Tree";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TextFieldItem from "@zert-packages/components/TextFieldItem";
import Loader from "@zert-packages/components/shared/Loader/Loader";
import DropMenu from "@zert-packages/components/DropMenu";

import FormControl from "@mui/material/FormControl";
import { LayoutContext } from "../LayoutView";

const useStyles = makeStyles((theme) => ({
  rootExpanderElementProperties: {
    flexGrow: 1
  },
  rootAccordion: {
    borderBottomLeftRadius: "0px !important",
    borderBottomRightRadius: "0px !important",
    borderBottom: "1px solid #E2F2F6",
    height: "100%",
    flex: '0 1 auto',
    "& .MuiCollapse-wrapper, .MuiAccordionDetails-root, #panel1a-content": {
      height: "100%"
    },
    "& .MuiCollapse-root": {
      height: "100%"
    }
  },
  dropMenuButton: {
    width: "100%",
    justifyContent: "space-between",
    border: "1px solid #DCE3E5",
    marginBottom: 10,
    color: "#8B8B8B",
    fontWeight: "400",
    paddingBottom: 2,
    paddingTop: 2,
    background: "#ffffff",
    paddingRight: 15,
    borderRadius: 0,
    outline: "none !important",
    "&:hover": {
      background: "#ffffff"
    },
    "& span:nth-child(2)": {
      display: "none"
    }
  },
  menuLinkSiteWrapper: {
    borderRadius: 0,
    // width: 470,
    width: "fit-content",
    minWidth: 315,
    maxWidth: "Calc(100% - 40px)",
    marginTop: 40,
    border: "1px solid #DCE3E5",
    boxShadow: "none",
    "@media (max-width: 900px)": {
      marginTop: 45,
      width: "Calc(100% - 151px)"
    }
  },
  AccordionDetailsWrapper: {
    height: "100%",
    padding: "8px 0px !important",
    overflow: "hidden"
  },
  rootAccordionDetails: {
    width: "100%",
    height: "100%",
    padding: "0px 16px",
    // position: 'absolute',
    // overflow: 'auto',
    paddingBottom: 15,
  },
  checkboxLabel: {
    fontSize: 15
  },
  wrapperSearch: {
    display: "grid",
    gridTemplateColumns: "1fr 45px",
    gridColumnGap: 10,
    marginTop: 5
  },
  wrapperArrow: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 1px"
  },
  arrowUp: {
    color: "#4db1d3",
    cursor: "pointer",
    transform: "rotate(90deg)"
  },
  arrowDown: {
    color: "#4db1d3",
    cursor: "pointer",
    transform: "rotate(270deg)"
  },
  rootLayoutHandler: {
    display: "grid",
    gridRowGap: 5,
    marginTop: 10
  },
  wrapperLayoutHandler: {
    display: "grid",
    gridTemplateColumns: "115px 1fr",
    gridColumnGap: 5,
  },
  label: {
    fontWeight: "900"
  },
  radioRoot: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    marginLeft: 12,
  },
  radioItem: {
    display: 'flex',
    alignItems: 'baseline',
    gap: 5,
  }
}));

const Structure = ({ expanded, setExpanded }) => {
  const cl = useStyles();
  const { values, setValues } = useContext(LayoutContext);


  const handleChange = () => {
    setExpanded({ ...expanded, expandedPanelOne: !expanded.expandedPanelOne });
  };

  const textFieldLayoutChangeHandler = (value, name) => {
    setValues((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleRadio = (event) => {
    const value = event.target.value
    textFieldLayoutChangeHandler(value, 'externalContent')
  }


  return (<div className={expanded.expandedPanelOne ? cl.rootExpanderElementProperties : ""}>
    <Accordion
      expanded={expanded.expandedPanelOne}
      onChange={() => handleChange("panel1")}
      style={{
        height: expanded.expandedPanelOne ? "Calc(100% - 46px) !important" : ""
      }}
      className={cl.rootAccordion}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        Structure
      </AccordionSummary>
      <AccordionDetails className={cl.AccordionDetailsWrapper}>
        <div className={cl.rootAccordionDetails}>
        <div>
          <div style={{ marginBottom: 5 }}>Language:</div>
          <SelectedLanguagePopup />
          <FormControlLabel
            control={
              <Checkbox
              />
            }
            label={ <span className={cl.checkboxLabel}>
                  <img src={translateable} alt="translateable" /> Translatable
                </span> }
          />
          <div>
            <Tree
              overflowHidden={true}
              structureMain={true}
            />
          </div>

          <div className={cl.wrapperSearch}>
            <TextFieldItem
              name={"nameBlock"}
            />
            <div className={cl.wrapperArrow}>
              <ArrowBackIcon className={cl.arrowUp} />
              <ArrowBackIcon className={cl.arrowDown} />
            </div>
          </div>
          {!values
            ? <Loader /> :
            <div className={cl.rootLayoutHandler}>
              <div className={cl.wrapperLayoutHandler}>
                <span className={cl.label}>Name:</span>
                <TextFieldItem
                  values={values?.name}
                  name={"name"}
                  setValues={textFieldLayoutChangeHandler}
                />
              </div>
              <div className={cl.wrapperLayoutHandler}>
                <span className={cl.label}>Description:</span>
                <TextFieldItem
                  values={values?.description}
                  name={"description"}
                  multiline={true}
                  row={4}
                  setValues={textFieldLayoutChangeHandler}
                />
              </div>
              <div className={cl.wrapperLayoutHandler}>
                <span className={cl.label}>Stylesheet:</span>
                <DropMenu
                  selected={values?.stylesheetInfo.name}
                />
              </div>
              <div className={cl.wrapperLayoutHandler}>
                <span className={cl.label}>File extension:</span>
                <TextFieldItem
                  values={values?.fileExtension}
                  name={"fileExtension"}
                  multiline={true}
                  row={4}
                  setValues={textFieldLayoutChangeHandler}
                />
              </div>
              <div className={cl.wrapperLayoutHandler}>
                <span className={cl.label}>Formatter:</span>
                <DropMenu
                  selected={values.formatter}
                  name={"formatter"}
                />
              </div>

              <div className={cl.wrapperLayoutHandler}>
                <span className={cl.label}>External content:</span>
                <FormControl>
                <RadioGroup
                  className={cl.radioRoot}
                  onChange={handleRadio}
                  value={values.externalContent}
                >
                  <FormControlLabel
                    className={cl.radioItem}
                    value={true}
                    control={<Radio color="primary" />}
                    label={<span>Yes</span>}
                  />
                  <FormControlLabel
                    className={cl.radioItem}
                    value={false}
                    control={<Radio color="primary" />}
                    label={<span>False</span>}
                  />
                </RadioGroup>
                </FormControl>
              </div>
            </div>}
        </div>
        </div>
      </AccordionDetails>
    </Accordion>
  </div>);
};

export default Structure;