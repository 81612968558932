import './editorStyle.css';
import React from 'react';
import { Editor, EditorState, ContentState, convertFromHTML, RichUtils } from 'draft-js';
import { FormattedMessage } from 'react-intl';
import DOMPurify from 'dompurify';

export default class RichTextEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { editorState: EditorState.createEmpty() };

    this.focus = () => this.refs.editor.focus();
    this.onChange = (editorState) => {
      this.setState({ editorState });
      if (
        !editorState.getCurrentContent().equals(this.state.editorState.getCurrentContent()) ||
        !editorState.getCurrentInlineStyle().equals(this.state.editorState.getCurrentInlineStyle())
      ) {
        if (this.props.onChange) {
          this.props.onChange(editorState);
        }
      }
    };

    this.onBlur = () => {
      if (this.props.onBlur) {
        this.props.onBlur(this.state.editorState);
      }
    };
    this.handleReturn = (command) => this._handleReturn(command);
    this.handleKeyCommand = (command) => this._handleKeyCommand(command);
    this.onTab = (e) => this._onTab(e);
    this.toggleBlockType = (type, isinline) => this._toggleBlockType(type, isinline);
    // this.toggleInlineStyle = (style) => this._toggleInlineStyle(style);
    try {
      if (this.props.text) {
        this.state = {
          editorState: EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(this.props.text))
          )
        };
      }
    } catch (e) {}
  }

  _handleReturn(command) {
    const { editorState } = this.state;
    if (command.shiftKey) {
      this.setState({ editorState: RichUtils.insertSoftNewline(editorState) });
      return 'handled';
    }
    return 'not-handled';
  }

  _handleKeyCommand(command) {
    const { editorState } = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  _onTab(e) {
    const maxDepth = 4;
    this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
  }

  _toggleBlockType(blockType, isinline) {
    if (!isinline) {
      this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
    } else {
      this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, blockType));
    }
  }

  _toggleInlineStyle(inlineStyle) {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle));
  }

  render() {
    const { editorState } = this.state;

    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = 'RichEditor-editor';
    const contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        className += ' RichEditor-hidePlaceholder';
      }
    }

    if (this.props.readonly) {
      return (
        <div
          data-searchable-element="1"
          className="RichEditor-root"
          style={this.props.style}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props.text) }}
        />
      );
    }
    return (
      <div data-searchable-element="1" data-deep-search="true" className="RichEditor-root" style={this.props.style}>
        <BlockStyleControls editorState={editorState} onToggle={this.toggleBlockType} />

        <div className={className} onClick={this.focus}>
          <Editor
            readOnly={this.props.readonly}
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={this.handleKeyCommand}
            handleReturn={this.handleReturn}
            onChange={this.onChange}
            onBlur={this.onBlur}
            onTab={this.onTab}
            ref="editor"
            spellCheck
            stripPastedStyles
          />
        </div>
      </div>
    );
  }
}

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2
  }
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote RichEditor-default';
    default:
      return 'RichEditor-default';
  }
}

class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style, this.props.isinline);
    };
  }

  render() {
    let className = 'RichEditor-styleButton';
    if (this.props.active) {
      className += ' RichEditor-activeButton';
    }

    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}

const BLOCK_TYPES = [
  {
    label: (
      <b>
        <FormattedMessage id="environment.richtext.bold" defaultMessage="Bold" />
      </b>
    ),
    style: 'BOLD',
    isinline: true
  },
  {
    label: (
      <i>
        <FormattedMessage id="environment.richtext.italic" defaultMessage="Italic" />
      </i>
    ),
    style: 'ITALIC',
    isinline: true
  },
  {
    label: (
      <u>
        <FormattedMessage id="environment.richtext.underline" defaultMessage="Underline" />
      </u>
    ),
    style: 'UNDERLINE',
    isinline: true
  },
  {
    label: <FormattedMessage id="environment.richtext.header" defaultMessage="Header" />,
    style: 'header-two',
    isinline: false
  },
  {
    label: <FormattedMessage id="environment.richtext.list" defaultMessage="List" />,
    style: 'unordered-list-item',
    isinline: false
  },
  {
    label: <FormattedMessage id="environment.richtext.numberedList" defaultMessage="Numbered list" />,
    style: 'ordered-list-item',
    isinline: false
  }
];

function BlockStyleControls(props) {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type, index) => (
        <StyleButton
          key={index}
          active={type.style === blockType || currentStyle.has(type.style)}
          label={type.label}
          isinline={type.isinline}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
}
