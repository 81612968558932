import React, { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getApiTree } from './API';
import Tree from './Tree/index';
import { PanelContext } from '../InfoElementWrapper/InfoElementWrapper';

const useStyles = makeStyles((theme) => ({
  rootExpanderElementProperties: {
    flexGrow: 1
  },
  dropMenuButton: {
    width: '100%',
    justifyContent: 'space-between',
    border: '1px solid #DCE3E5',
    marginBottom: 15,
    color: '#8B8B8B',
    fontWeight: '400',
    paddingBottom: 2,
    paddingTop: 2,
    background: '#ffffff',
    paddingRight: 15,
    borderRadius: 0,
    outline: 'none !important',
    '&:hover': {
      background: '#ffffff'
    },
    '& span:nth-child(2)': {
      display: 'none'
    }
  },
  menuLinkSiteWrapper: {
    borderRadius: 0,
    // width: 470,
    width: 'fit-content',
    minWidth: 315,
    maxWidth: 'calc(100% - 40px)',
    marginTop: 40,
    border: '1px solid #DCE3E5',
    boxShadow: 'none',
    '@media (max-width: 900px)': {
      marginTop: 45,
      width: 'calc(100% - 151px)'
    }
  },
  wrapperLanguage: {
    padding: '0px 16px'
  },
  wrapperTree: (props) => ({
    width: '100%',
    '& .MuiTreeItem-content': {
      cursor: props.blockClick ? 'not-allowed' : '',
      '&:hover': {
        backgroundColor: props.blockClick ? '#ffffff' : ''
      }
    }
  })
}));

function StructurePane({ context }) {
  const { values } = useContext(context || PanelContext);
  const blockClick = values?.blockClickCreateItemsBlock;
  const cl = useStyles({ blockClick });
  const { versionId } = useParams();
  const { myLanguage } = useSelector((state) => state);

  const getTree = async () => {
    return getApiTree(versionId, myLanguage?.value);
  };

  return (
    <div className={cl.wrapperTree}>
      <Tree getRootNode={() => getTree()} structureMain blockClick={blockClick} />
    </div>
  );
}
export default StructurePane;
