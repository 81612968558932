import {initLayout, storeLayout} from "../API";

const createListItem = async (nameCategory, name) => {
    const res = await initLayout(nameCategory)
    const data = {
        ...res,
        ...name,
    }
    const storeRes = await storeLayout(nameCategory, res.elementInfo.versionId, data)
    window.location.reload()
    return {...data, ...data.elementInfo}
}

export default createListItem