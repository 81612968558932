import React from 'react'
import {getPluginList} from "../API";
import TemplatesOverview from "@zert-packages/components/TemplatesOverview/TemplatesOverview";
import createListItem from "../helpers/createListItem ";


const TemplateList = () => {

    const fetchLayoutList =  () => {
        return  getPluginList('formattemplate')
    }

    const addListItem =  async (name) => {
        return createListItem('formattemplate', name)
    }


    return(<div className="PersonalSettings__MainContainer">

        <TemplatesOverview
            templateName={'Format Template'}
            fetchTemplates={fetchLayoutList}
            initTemplate={addListItem}
        />
    </div>)
}

export default TemplateList