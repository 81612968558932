import React, { useEffect, useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  stickyStyle: (props) => ({
    position: 'sticky',
    top: `${props.top}px`,
    zIndex: props.zIndex,
    ...props.appendStickyStyle
  }),
  unStickyStyle: (props) => ({
    ...props.appendUnStickyStyle
  })
}));

function HierarchicalStickyHeader({ stickyCondition, identifier, appendStickyStyle, appendUnStickyStyle, children }) {
  const ref = useRef();
  const [top, setTop] = useState(0);
  const [zIndex, setZindex] = useState(100);
  const cl = useStyles({ appendStickyStyle, appendUnStickyStyle, top, zIndex });

  useEffect(() => {
    const calculateTop = () => {
      const element = ref.current;
      if (!element) return;

      let cumulativeHeight = 0;
      let cumulativeZindex = zIndex;
      let currentElement = element;

      while (currentElement) {
        const parent = currentElement.parentElement;

        if (parent) {
          const siblings = Array.from(parent.children);

          for (const sibling of siblings) {
            if (sibling === currentElement) break;
            if (sibling.classList.contains(identifier)) {
              cumulativeHeight += sibling.offsetHeight;
              cumulativeZindex--;
            }
          }
        }

        currentElement = parent;
      }

      setTop(cumulativeHeight);
      setZindex(cumulativeZindex);
    };

    calculateTop();
    window.addEventListener('resize', calculateTop);
    return () => window.removeEventListener('resize', calculateTop);
  }, [stickyCondition]);

  return (
    <div ref={ref} className={`${stickyCondition ? `${cl.stickyStyle} ${identifier}` : cl.unStickyStyle}`}>
      {children}
    </div>
  );
}

export default HierarchicalStickyHeader;
