import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'grid',
    gridGap: '10px',
    gridTemplateColumns: '1fr'
  }
});

function getInitialState() {
  return {
    name: ''
  };
}

function CreateChecklistItem({ onCreate, handleClose }) {
  const classes = useStyles();

  const [state, setState] = useState(getInitialState());

  const handleChange = (name, value) => {
    setState((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCancel = () => handleClose();
  const handleConfirm = () => {
    onCreate(state);
    handleClose();
  };
  return (
    <Dialog open onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        <FormattedMessage id="CreateCheckListItemDialog.title" defaultMessage="New item" />
      </DialogTitle>
      <DialogContent>
        <div className={classes.container}>
          <TextField
            variant="standard"
            fullWidth
            label="Name"
            value={state.name}
            onChange={(event) => handleChange('name', event.target.value)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          <FormattedMessage id="CreateCheckListItemDialog.cancel" defaultMessage="Cancel" />
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          <FormattedMessage id="CreateCheckListItemDialog.create" defaultMessage="Create" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateChecklistItem;
