import {
  handlePost,
  handlePostFile,
  handlePostResultAsString,
  handleQuery,
  handlePostTextAsParam,
  handleText,
  fetchRA_Error,
  fetchSTORE_Error,
  fetchElementStoreStart,
  handlePostTextAsParamResultAsText,
  getPluginsList
} from './api';
import { FETCH_TEMPLATES } from './actions';



const VERSION_INFO_ACTION = 'VERSION_INFO_ACTION';
const VERSION_INFO_ACTION_START = 'VERSION_INFO_ACTION_START';

const PLUGIN_PERMIT_ACTION = 'PLUGIN_PERMIT_ACTION';
const PLUGIN_PERMIT_LIST_ACTION = 'PLUGIN_PERMIT_LIST_ACTION';
const PLUGIN_PERMIT_START_ACTION = 'PLUGIN_PERMIT_START_ACTION';


const PLUGIN_ACTION_HEADER = 'PLUGIN_ACTION_HEADER';



const HAS_LICENSE_ACTION = 'HAS_LICENSE_ACTION';
const HAS_LICENSE_START_ACTION = 'HAS_LICENSE_START_ACTION';

const LICENSE_TEXT_ACTION = 'LICENSE_TEXT_ACTION';

const ACTIVE_LOCALES_ACTION = 'ACTIVE_LOCALES_ACTION';
const ACTIVE_METADATATEMPLATES_ACTION = 'ACTIVE_METADATATEMPLATES_ACTION';
const FORMAT_TEMPLATE = 'FORMAT_TEMPLATE';
const PUBLISHING_FILTERS = 'PUBLISHING_FILTERS';

const LICENSE_TEXT_ACTION_START = 'LICENSE_TEXT_START_ACTION';

const DELETE_DONE = 'DELETE_DONE';
const ERROR_DELETE = 'ERROR_DELETE';
const DELETE_START = 'DELETE_START';
const DELETE_STATUS = 'DELETE_STATUS';

const CATALOG_PLUGIN_PERMIT_ACTION = 'CATALOG_PLUGIN_PERMIT_ACTION';
const CATALOG_PLUGIN_PERMIT_START_ACTION = 'CATALOG_PLUGIN_PERMIT_ACTION';
const RISKROWS_TEMPLATES_START = 'RISKROWS_TEMPLATES_START';
const RISKROWS_TEMPLATES_DONE = 'RISKROWS_TEMPLATES_DONE';
const WORKFLOW_TEMPLATES_DONE = 'WORKFLOW_TEMPLATES_DONE';
const LOCK_SIDE_PANEL = 'LOCK_SIDE_PANEL';
const WORKFLOW_TEMPLATES_START = 'WORKFLOW_TEMPLATES_START';
export const SELECT_COMPANY = 'SELECT_COMPANY';
export const SELECT_USER = 'SELECT_USER';
export const SET_ROUTE = 'SET_ROUTE';
export const SELECT_CHECKLIST_ITEM = 'SELECT_CHECKLIST_TEMPLATE_ITEM';
export const SELECT_SAFETY_INSPECTION_ITEM = 'SELECT_SAFETY_INSPECTION_ITEM';

const REDUCER_CATALOG_PLUGIN_PERMIT = (pluginname, permit) => ({
  type: CATALOG_PLUGIN_PERMIT_ACTION,
  payload: {
    pluginname,
    permit
  }
});

const REDUCER_CATALOG_PLUGIN_PERMIT_START = () => ({
  type: CATALOG_PLUGIN_PERMIT_START_ACTION,
  payload: {}
});

const REDUCER_DELETE_DONE = () => ({
  type: DELETE_DONE,
  payload: {}
});

const REDUCER_ERROR_DELETE = (delete_error) => ({
  type: ERROR_DELETE,
  payload: { delete_error }
});

const REDUCER_DELETE_START = () => ({
  type: DELETE_START,
  payload: {}
});

export const fetchSetRoute = (route) => ({
  type: SET_ROUTE,
  payload: { route }
});

const REDUCER_DELETE_STATUS = (delete_callback) => ({
  type: DELETE_STATUS,
  payload: { delete_callback }
});

const REDUCER_VERSION_ACTION = (info) => ({
  type: VERSION_INFO_ACTION,
  payload: { info }
});

const REDUCER_VERSION_ACTION_START = () => ({
  type: VERSION_INFO_ACTION_START,
  payload: {}
});

const REDUCER_PLUGIN_PERMIT = (pluginname, permit) => ({
  type: PLUGIN_PERMIT_ACTION,
  payload: {
    pluginname,
    permit
  }
});

const REDUCER_PLUGIN_PERMIT_LIST = (permits) => ({
  type: PLUGIN_PERMIT_LIST_ACTION,
  payload: {
    permits
  }
});

const REDUCER_PLUGIN_PERMIT_START = () => ({
  type: PLUGIN_PERMIT_START_ACTION,
  payload: {}
});

const REDUCER_LICENSE_TEXT = (license) => ({
  type: LICENSE_TEXT_ACTION,
  payload: { license }
});

const REDUCER_ACTIVE_LOCALES_ACTION = (locales) => ({
  type: ACTIVE_LOCALES_ACTION,
  payload: { locales }
});

const REDUCER_METADATA_TEMPLATES = (templates) => ({
  type: ACTIVE_METADATATEMPLATES_ACTION,
  payload: { templates }
});

const REDUCER_FORMAT_TEMPLATE = (template) => ({
  type: FORMAT_TEMPLATE,
  payload: { template }
});

const REDUCER_PUBLISHING_FILTERS = (filters) => ({
  type: PUBLISHING_FILTERS,
  payload: { filters }
});

const REDUCER_LICENSE_TEXT_START = () => ({
  type: LICENSE_TEXT_ACTION_START,
  payload: {}
});

const REDUCER_HAS_LICENSE = (hasLicense) => ({
  type: HAS_LICENSE_ACTION,
  payload: { hasLicense }
});

const REDUCER_HAS_LICENSE_START = () => ({
  type: HAS_LICENSE_START_ACTION,
  payload: {}
});

export function storeCompany(data) {
  handlePostResultAsString(`/admin/storeCompany/${data.companyId}`, data);
}

const STORE_COMPANY_ACTION = 'STORE_COMPANY_ACTION';
export const REDUCER_STORE_COMPANY = () => ({
  type: STORE_COMPANY_ACTION,
  payload: {}
});

export function STORE_COMPANY() {
  return function (dispatch) {
    return dispatch(REDUCER_STORE_COMPANY());
  };
}

const REDUCER_SETTINGS_START = () => ({
  type: SETTINGS_START
});

const REDUCER_RISKROWS_TEMPLATES_START = () => ({
  type: RISKROWS_TEMPLATES_START
});

const REDUCER_RISKROWS_TEMPLATES_DONE = (templates) => ({
  type: RISKROWS_TEMPLATES_DONE,
  payload: { templates }
});

export function getRiskRowTemplates(companyId) {
  return function (dispatch) {
    handleQuery(`/common/getRiskRowTemplates/${companyId}`)
      .then((templates) => {
        dispatch(REDUCER_RISKROWS_TEMPLATES_DONE(templates));
      })
      .catch((error) => {
        dispatch(fetchRA_Error(error));
      });
    return dispatch(REDUCER_RISKROWS_TEMPLATES_START());
  };
}

const REDUCER_WORKFLOW_TEMPLATES_START = () => ({
  type: WORKFLOW_TEMPLATES_START
});

const REDUCER_WORKFLOW_TEMPLATES_DONE = (templates) => ({
  type: WORKFLOW_TEMPLATES_DONE,
  payload: { templates }
});

//export function STORE_COMPANY() {
//   return function (dispatch) {
//     return dispatch(REDUCER_STORE_COMPANY());
//   };
// }
export const handlePluginAction = (type) => {
  return function (dispatch) {
    return dispatch({
      type: PLUGIN_ACTION_HEADER,
      payload: type
    })
  }
}

export const setLockSidePanel = (isLocked) => {
  return function (dispatch) {
    return dispatch({
      type: LOCK_SIDE_PANEL,
      payload: isLocked
    })
  }
}


export function getThirdPartProducts() {
  return handleQuery('/common/thirdPartyProducts');
}

export function getWorkflowTemplates(companyId) {
  return function (dispatch) {
    handleQuery(`/common/getWorkflowTemplates/${companyId}`)
      .then((templates) => {
        dispatch(REDUCER_WORKFLOW_TEMPLATES_DONE(templates));
      })
      .catch((error) => {
        dispatch(fetchRA_Error(error));
      });
    return dispatch(REDUCER_WORKFLOW_TEMPLATES_START());
  };
}

export function getOrigin(versionId) {
  return handleQuery(`/common/getOrigin/${versionId}`);
}

export function canLoad(versionId) {
  return handleQuery(`/common/canLoad/${versionId}`);
}

export function createGroupAsync(companyId, group) {
  return handlePost(`/admin/createGroup/${companyId}`, group);
}

export function createUserAsync(companyId, user, isAdmin) {
  return isAdmin
    ? handlePost(`/admin/createUser/${companyId}`, user)
    : handlePost(`/companyAdmin/createUser/${companyId}`, user);
}

export function storeGroupAsync(companyId, groupId, group) {
  return handlePost(`/admin/storeGroup/${companyId}/${groupId}`, group);
}

export function deleteGroupAsync(companyId, groupId) {
  return handleQuery(`/admin/deleteGroup/${companyId}/${groupId}`);
}

export function hasUser(userName, isAdmin) {
  if (isAdmin) {
    return handlePostTextAsParamResultAsText('/admin/hasUser', userName);
  } else {
    return handlePostTextAsParamResultAsText('/companyAdmin/hasUser', userName);
  }
}

export function getSchedulerSettings(companyId, className) {
  return handleQuery(`/admin/getSettings/${companyId}/${className}`);
}

export function storeSchedulerSettings(companyId, className, storage) {
  return handlePostResultAsString(`/admin/storeScheduleTask/${companyId}/${className}`, storage);
}

export function getSettings() {
  return function (dispatch) {
    handleQuery('/common/getSetting')
      .then((settings) => {
        dispatch(REDUCER_SETTINGS_DONE(settings));
      })
      .catch((error) => dispatch(fetchSTORE_Error(error)));
    return dispatch(REDUCER_SETTINGS_START());
  };
}

const SETTINGS_DONE = 'SETTINGS_DONE';
const SETTINGS_START = 'SETTINGS_START';
const SETTINGS_STORE_DONE = 'SETTINGS_STORE_DONE';

const REDUCER_SETTINGS_STORE_DONE = () => ({
  type: SETTINGS_STORE_DONE
});

const REDUCER_SETTINGS_DONE = (settings) => ({
  type: SETTINGS_DONE,
  payload: {
    settings
  }
});

export function loadPermissions(companyid) {
  return function (dispatch) {
    handleQuery(`/admin/loadPermissions/${companyid}`)
      .then((permissions) => {
        dispatch(REDUCER_PERMISSIONS_DONE(permissions, companyid));
      })
      .catch((error) => dispatch(fetchSTORE_Error(error)));
    return dispatch(REDUCER_PERMISSIONS_START());
  };
}

const PERMISSIONS_DONE = 'PERMISSIONS_DONE';
const PERMISSIONS_START = 'PERMISSIONS_START';

const REDUCER_PERMISSIONS_DONE = (permissions, companyid) => ({
  type: PERMISSIONS_DONE,
  payload: {
    permissions,
    companyid
  }
});

const REDUCER_PERMISSIONS_START = () => ({
  type: PERMISSIONS_START,
  payload: {}
});

export function storeSettings(settings) {
  return function (dispatch) {
    handlePost('/common/storeSetting', settings)
      .then(() => {
        dispatch(REDUCER_SETTINGS_START());

        return handleQuery('/common/getSetting');
      })
      .then(() => {
        dispatch(REDUCER_SETTINGS_DONE(settings));
        dispatch(REDUCER_SETTINGS_STORE_DONE());
      })
      .catch((error) => dispatch(fetchSTORE_Error(error)));
    return dispatch(REDUCER_SETTINGS_START());
  };
}

export function setCompany(companyToEdit) {
  // return fetchMyCompanyDone(companyToEdit);
}

const USERS_LIST_START = 'USERS_LIST_START';
const USERS_LIST_DONE = 'USERS_LIST_DONE';

const REDUCER_USERS_LIST_DONE = (userslist) => ({
  type: USERS_LIST_DONE,
  payload: {
    userslist
  }
});

const REDUCER_USERS_LIST_START = () => ({
  type: USERS_LIST_START
});

export function loadUserList(companyId, groupId, isAdmin) {
  const query = isAdmin ? `/admin/getUserList/${companyId}/${groupId}` : `/companyAdmin/getUserList/${companyId}/${groupId}`
  return function (dispatch) {
    handleQuery(query)
      .then((userslist) => {
        dispatch(REDUCER_USERS_LIST_DONE(userslist));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_USERS_LIST_START());
  };
}

const COMPANY_SELECT = 'COMPANY_SELECT';
export const REDUCER_COMPANY_SELECT = (company) => ({
  type: COMPANY_SELECT,
  selectedCompany: company
});

const COMPANY_START = 'COMPANY_START';
const COMPANY_DONE = 'COMPANY_DONE';

const REDUCER_COMPANY_DONE = (company) => ({
  type: COMPANY_DONE,
  payload: {
    company
  }
});

const REDUCER_COMPANY_START = () => ({
  type: COMPANY_START
});

export function loadCompany(companyid, isAdmin) {
  const query = isAdmin
    ? `/admin/getCompany/${companyid}`
    : `/companyAdmin/getCompany/${companyid}`
  return function (dispatch) {
    handleQuery(query)
      .then((company) => {
        dispatch(REDUCER_COMPANY_DONE(company));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_COMPANY_START());
  };
}

const USER_START = 'USER_START';
const USER_DONE = 'USER_DONE';

const REDUCER_USER_START = () => ({
  type: USER_START,
  payload: {}
});

const REDUCER_USER_DONE = (user) => ({
  type: USER_DONE,
  payload: {
    user
  }
});

export function loadUser(companyid, userId, isAdmin) {
  const query = isAdmin
    ? `/admin/getUser/${companyid}/${userId}`
    : `/companyAdmin/getUser/${companyid}/${userId}`
  return function (dispatch) {
    handleQuery(query)
      .then((user) => {
        dispatch(REDUCER_USER_DONE(user));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_USER_START());
  };
}

const MATRIX_TEMPLATES_START = 'MATRIX_TEMPLATES_START';
const MATRIX_TEMPLATES_DONE = 'MATRIX_TEMPLATES_DONE';
const REDUCER_MATRIX_TEMPLATES_DONE = (companyId, templateId, configuration) => ({
  type: MATRIX_TEMPLATES_DONE,
  payload: {
    companyId,
    templateId,
    configuration
  }
});

const REDUCER_MATRIX_TEMPLATES_START = (companyId, templateId) => ({
  type: MATRIX_TEMPLATES_START,
  payload: {
    companyId,
    templateId
  }
});

export function loadMatrixTemplates(companyId, templateId) {
  return function (dispatch) {
    handleQuery(`/admin/getMatrixConfiguration/${companyId}/${templateId}`)
      .then((configuration) => {
        dispatch(REDUCER_MATRIX_TEMPLATES_DONE(companyId, templateId, configuration));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_MATRIX_TEMPLATES_START(companyId, templateId));
  };
}

export function storeMatrixConfiguration(companyId, templateId, configuration) {
  handlePostResultAsString(`/admin/storeMatrixConfiguration/${companyId}/${templateId}`, configuration);
}

export function storePermissions(companyId, permissions) {
  handlePostResultAsString(`/admin/storePermissions/${companyId}`, permissions);
}

const MATRIX_START = 'MATRIX_START';
const MATRIX_DONE = 'MATRIX_DONE';
const REDUCER_MATRIX_DONE = (companyId, matrixes) => ({
  type: MATRIX_DONE,
  payload: {
    companyId,
    matrixes
  }
});

const REDUCER_MATRIX_START = (companyId, templateId) => ({
  type: MATRIX_START,
  payload: {
    companyId
  }
});

export function loadMatrixes(companyId) {
  return function (dispatch) {
    handleQuery(`/admin/getMatrixes/${companyId}`)
      .then((matrixes) => {
        dispatch(REDUCER_MATRIX_DONE(companyId, matrixes));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_MATRIX_START(companyId));
  };
}

const GROUPS_LIST_START = 'GROUPS_LIST_START';
const GROUPS_LIST_DONE = 'GROUPS_LIST_DONE';

const REDUCER_GROUPS_LIST_DONE = (groupslist) => ({
  type: GROUPS_LIST_DONE,
  payload: {
    groupslist
  }
});

const REDUCER_GROUPS_LIST_START = () => ({
  type: GROUPS_LIST_START
});

export function loadGroupsList(companyId, isAdmin) {
  const query = isAdmin
    ? `/admin/getGroupsList/${companyId}`
    : `/companyAdmin/getGroupsList/${companyId}`;
  return function (dispatch) {
    handleQuery(query)
      .then((groupslist) => {
        dispatch(REDUCER_GROUPS_LIST_DONE(groupslist));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_GROUPS_LIST_START());
  };
}

const COMPANIES_LIST_START = 'COMPANIES_LIST_START';
const COMPANIES_LIST_DONE = 'COMPANIES_LIST_DONE';

const REDUCER_COMPANIES_LIST_DONE = (companieslist) => ({
  type: COMPANIES_LIST_DONE,
  payload: {
    companieslist
  }
});

const REDUCER_COMPANIES_LIST_START = () => ({
  type: COMPANIES_LIST_START
});

const CREATE_COMPANY_START = 'CREATE_COMPANY_START';
const CREATE_COMPANY_DONE = 'CREATE_COMPANY_DONE';

const REDUCER_CREATE_COMPANY_DONE = (company) => ({
  type: CREATE_COMPANY_DONE,
  payload: {
    company
  }
});

const REDUCER_CREATE_COMPANY_START = () => ({
  type: CREATE_COMPANY_START,
  payload: {}
});

export function loadCompaniesList(includeInactive) {
  return function (dispatch) {
    handleQuery(`/admin/getCompanyList/${includeInactive}`)
      .then((company) => {
        dispatch(REDUCER_COMPANIES_LIST_DONE(company));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_COMPANIES_LIST_START());
  };
}

const COMPANY_TEMPLATES_START = 'COMPANY_TEMPLATES_START';
const COMPANY_TEMPLATES_DONE = 'COMPANY_TEMPLATES_DONE';

const REDUCER_COMPANY_TEMPLATES_DONE = (templates) => ({
  type: COMPANY_TEMPLATES_DONE,
  payload: {
    templates
  }
});

const REDUCER_COMPANY_TEMPLATES_START = () => ({
  type: COMPANY_TEMPLATES_START,
  payload: {}
});

export function loadTemplates(companyId, includeDefault) {
  return function (dispatch) {
    handleQuery(`/admin/getCompanyTemplates/${includeDefault}/${companyId}`)
      .then((templates) => {
        dispatch(REDUCER_COMPANY_TEMPLATES_DONE(templates));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_COMPANY_TEMPLATES_START());
  };
}

export function storeTemplates(companyId, templates) {
  return handlePostResultAsString(`/admin/storeCompanyTemplates/${companyId}`, templates);
}

export function storeMailSettings(settings) {
  return handlePostResultAsString('/common/storeMailSettings', settings);
}

export function createVersion(companyId, versionId) {
  return handlePostTextAsParam(`/common/createVersion/${companyId}/${versionId}`, '{}');
}

export function approveVersionAsync(companyId, versionId) {
  return handlePostTextAsParamResultAsText(`/common/approve/${companyId}/${versionId}`, '{}');
}

export function mailSettings() {
  return handleQuery('/common/mailSettings');
}

export function createCompany(name, user, locale) {
  return function (dispatch) {
    handleQuery(`/admin/createCompany/${name}/${user}/` + `sv_SE`)
      .then((company) => {
        dispatch(REDUCER_CREATE_COMPANY_DONE(company));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_CREATE_COMPANY_START());
  };
}

export function removeUser(userId, companyId, isAdmin) {
  const query = isAdmin
    ? `/admin/deleteUser/${companyId}/${userId}`
    : `/companyAdmin/deleteUser/${companyId}/${userId}`
  return function (dispatch) {
    handleQuery(query)
      .then(() => {
        dispatch(loadUserList(companyId, -1, isAdmin));
      })
      .catch((error) => dispatch(fetchSTORE_Error(error)));
    return dispatch(fetchElementStoreStart());
  };
}

export function removeCompany(companyId) {
  return function (dispatch) {
    handleQuery(`/admin/deleteCompany/${companyId}`)
      .then(() => {
        dispatch(loadCompaniesList(true));
        dispatch({
          type: SELECT_COMPANY,
          payload: { companyId }
        });
      })
      .catch((error) => dispatch(fetchSTORE_Error(error)));
    return dispatch(fetchElementStoreStart());
  };
}

export function addEditUser(companyId, data) {
  return function (dispatch) {
    handlePost(`/admin/addEditUser/${companyId}`, data)
      .then(() => {
        dispatch(loadUserList(companyId));
      })
      .catch((error) => dispatch(fetchSTORE_Error(error)));
    return dispatch(fetchElementStoreStart());
  };
}

export function storeUser(companyId, elementId, user, isAdmin) {
  const query = isAdmin
    ? `/admin/storeUser/${companyId}/${elementId}`
    : `/companyAdmin/storeUser/${companyId}/${elementId}`
  return handlePostResultAsString(query, user);
}

export const getVersionInfoAPI = (id) => handleQuery(`/common/versionInfo/${id}`);

export function getVersionInfo(id) {
  return function (dispatch) {
    handleQuery(`/common/versionInfo/${id}`)
      .then((info) => {
        dispatch(REDUCER_VERSION_ACTION(info));
      })
      .catch((error) => {
        dispatch(fetchRA_Error(error));
      });
    return dispatch(REDUCER_VERSION_ACTION_START());
  };
}

export function hasPluginPermit(pluginname) {
  return function (dispatch) {
    handlePostTextAsParam('/common/hasPluginPermit', pluginname)
      .then((result) => {
        dispatch(REDUCER_PLUGIN_PERMIT(pluginname, result));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_PLUGIN_PERMIT_START());
  };
}

export function hasPluginPermits() {
  return function (dispatch) {
    handlePost('/common/hasPluginPermits', getPluginsList())
      .then((result) => {
        dispatch(REDUCER_PLUGIN_PERMIT_LIST(result));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_PLUGIN_PERMIT_START());
  };
}

export function getOverviewSettings(pluginname) {
  return function (dispatch) {
    handleQuery(`/common/getForcedCatalogs/${pluginname}`)
      .then((result) => {
        dispatch(REDUCER_CATALOG_PLUGIN_PERMIT(pluginname, result));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_CATALOG_PLUGIN_PERMIT_START());
  };
}

export function hasLicense() {
  return function (dispatch) {
    handleText('/common/hasLicenseAgreement')
      .then((result) => {
        dispatch(REDUCER_HAS_LICENSE(result));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_HAS_LICENSE_START());
  };
}

export function getProvisionInfo() {
  return handleText('/common/safetyProvision');
}

export function getLicense() {
  return function (dispatch) {
    handleText('/common/licenseAgreement')
      .then((result) => {
        dispatch(REDUCER_LICENSE_TEXT(result));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_LICENSE_TEXT_START());
  };
}
export const getFormatTemplate = () => {
  return function (dispatch) {
    handleQuery('/formattemplate/getFormatTemplate', true)
      .then((result) => {
        dispatch(REDUCER_FORMAT_TEMPLATE(result));
      })
      .catch((error) => console.log('Error loading formatting template'));
    return dispatch(REDUCER_LICENSE_TEXT_START());
  };
};

export const getDocument = (versionId) => {
  return handleQuery(`/common/loadDocument/${versionId}`, true);
};

export const getPublishingFilters = () => {
  return function (dispatch) {
    handleQuery('/common/getPublishingFilters', true)
      .then((result) => {
        dispatch(REDUCER_PUBLISHING_FILTERS(result));
      })
      .catch((error) => console.log('Error loading publishing filtrers'));
    return dispatch(REDUCER_LICENSE_TEXT_START());
  };
};

export const getFormat = (versionId) => {
  return handleText(`/formattemplate/getFormat/${versionId}`);
};

export const getMetadataTemplates = () => {
  return function (dispatch) {
    handleQuery('/common/getMetadataTemplates')
      .then((result) => {
        dispatch(REDUCER_METADATA_TEMPLATES(result));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_LICENSE_TEXT_START());
  };
};

export const getActiveLocales = (sort) => {
  return function (dispatch) {
    handleQuery(`/common/getActiveLocales/${sort}`)
      .then((result) => {
        dispatch(
          REDUCER_ACTIVE_LOCALES_ACTION(
            result.map((locale) => ({
              ...locale,
              value: `${locale.language}_${locale.country}`,
              extendedDisplayName: `${locale.displayName} ${locale.language}_${locale.country}`
            }))
          )
        );
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_LICENSE_TEXT_START());
  };
};

export function coreReducer(state, action) {
  switch (action.type) {
    case PUBLISHING_FILTERS:
      return {
        ...state,
        publishingFilters: action.payload.filters
      };

    case FORMAT_TEMPLATE:
      return {
        ...state,
        formatTemplate: action.payload.template
      };
    case ACTIVE_LOCALES_ACTION:
      return {
        ...state,
        activeLocales: action.payload.locales
      };

    case ACTIVE_METADATATEMPLATES_ACTION:
      return {
        ...state,
        metadataTemplates: action.payload.templates
      };
    case MATRIX_START:
      return {
        ...state,
        loadingMatrixes: true,
        matrixes: null
      };
    case MATRIX_DONE:
      return {
        ...state,
        loadingMatrixes: false,
        matrixes: action.payload.matrixes
      };

    case MATRIX_TEMPLATES_START:
      return {
        ...state,
        loadingMatrixesConfiguration: true,
        configuration: null,
        matrixConfigurationCompanyId: action.payload.companyId,
        matrixConfigurationTemplateId: action.payload.templateId
      };
    case MATRIX_TEMPLATES_DONE:
      return {
        ...state,
        loadingMatrixesConfiguration: false,
        configuration: action.payload.configuration,
        matrixConfigurationCompanyId: action.payload.companyId,
        matrixConfigurationTemplateId: action.payload.templateId
      };
    case RISKROWS_TEMPLATES_START:
      return {
        ...state
      };
    case RISKROWS_TEMPLATES_DONE:
      return {
        ...state,
        riskrowTemplates: action.payload.templates
      };
    case SELECT_COMPANY:
      return {
        ...state,
        selectedCompanyId: action.payload.companyId
      };
    case SELECT_USER:
      return {
        ...state,
        selectedUserId: action.payload.userId
      };
    case SELECT_CHECKLIST_ITEM:
      return {
        ...state,
        selectedChecklistItem: action.payload.itemId
      };
    case FETCH_TEMPLATES:
      return {
        ...state,
        allTemplates: action.payload.templates
      };
    case SET_ROUTE:
      return {
        ...state,
        oldRoute: action.payload.route
      };
    case SELECT_SAFETY_INSPECTION_ITEM:
      return {
        ...state,
        selectedSafetyInspectionItem: action.payload.itemId
      };
    case WORKFLOW_TEMPLATES_DONE:
      return {
        ...state,
        workflowTemplates: action.payload.templates
      };
    case PERMISSIONS_DONE:
      return {
        ...state,
        permissions: {
          permissions: action.payload.permissions,
          companyid: action.payload.companyid
        }
      };
    case PERMISSIONS_START:
      return {
        ...state,
        permissions: null
      };
    case VERSION_INFO_ACTION:
      // Also, replace the items with the ones from the server
      return {
        ...state,
        versionInfo: action.payload.info,
        loading: false
      };

    case USER_DONE:
      return {
        ...state,
        loading: false,
        user: action.payload.user
      };

    case USER_START:
      return {
        ...state,
        loading: true,
        user: null
      };

    case COMPANY_DONE:
      return {
        ...state,
        loading: false,
        company: action.payload.company
      };

    case COMPANY_TEMPLATES_START:
      return {
        ...state,
        loadingTemplates: true,
        companyTemplates: []
      };
    case COMPANY_TEMPLATES_DONE:
      return {
        ...state,
        loadingTemplates: false,
        companyTemplates: action.payload.templates
      };

    case USERS_LIST_DONE:
      return {
        ...state,

        loadingUsers: false,
        userslist: action.payload.userslist
      };

    case GROUPS_LIST_DONE:
      return {
        ...state,
        groupslist: action.payload.groupslist
      };

    case USERS_LIST_START:
      return {
        ...state,

        loadingUsers: true,
        userslist: []
      };

    case COMPANIES_LIST_DONE:
      return {
        ...state,
        loading: false,
        companieslist: action.payload.companieslist
      };

    case COMPANIES_LIST_START:
      return {
        ...state,
        loading: true
      };

    case CATALOG_PLUGIN_PERMIT_ACTION:
      var permits = {};
      Object.assign(permits, state.catalogpermits);
      permits[action.payload.pluginname] = action.payload.permit;
      return {
        ...state,
        catalogpermits: permits
      };
    case PLUGIN_PERMIT_LIST_ACTION: {
      return {
        ...state,
        permits: action.payload.permits
      };
    }
    case PLUGIN_PERMIT_ACTION:
      var permits = {};
      Object.assign(permits, state.permits);
      permits[action.payload.pluginname] = action.payload.permit;
      return {
        ...state,
        permits
      };
    case CREATE_COMPANY_START:
      return {
        ...state,
        newcompany: null,
        create_company: true
      };
    case STORE_COMPANY_ACTION:
      return {
        ...state,
        newcompany: null,
        create_company: false
      };

    case COMPANY_START:
      return {
        ...state,
        loading: true
      };

    case CREATE_COMPANY_DONE:
      return {
        ...state,
        newcompany: action.payload.company,
        create_company: false,
        selectedCompanyId: action.payload.company.id
      };

    case LICENSE_TEXT_ACTION_START:
      return {
        ...state,
        licenseLoading: true
      };

    case DELETE_DONE:
      return {
        ...state,

        delete_done: true
      };
    case ERROR_DELETE:
      return {
        ...state,
        delete_done: false,
        delete_error: action.payload.delete_error
      };
    case DELETE_START:
      return {
        ...state,
        delete_error: null,
        delete_callback: null,
        delete_done: false
      };
    case DELETE_STATUS:
      return {
        ...state,
        delete_callback: action.payload.callback,
        delete_done: false
      };

    case LICENSE_TEXT_ACTION:
      return {
        ...state,
        license: action.payload.license,
        licenseLoading: false
      };

    case HAS_LICENSE_ACTION:
      return {
        ...state,
        hasLicense: action.payload.hasLicense
      };

    case PLUGIN_ACTION_HEADER:
      return {
        ...state,
        actionHeader: action.payload
      };

    case LOCK_SIDE_PANEL:
      return {
        ...state,
        lockSidePanel: action.payload
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
