import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import { loadRiskAssesmentTemplates } from '@zert-packages/actions/riskassesmentActions';
import { getPlugins } from '@zert-packages/actions/api';
import useDeviationReport from './useDeviationReport';
import useRiskAnalisisReport from './useRiskAnalisisReport'
import { RA_REPORT_TYPES } from './useRiskAnalisisReport';
import useSafetyInspectionReport from './saftetyInspection/useSafetyInspectionReport';
import useMeasureReport from './useMeasureReport';
import useSummaryXLSReports from './useSummaryXLSReports';
import { XLS_REPORT_TYPES } from './useSummaryXLSReports';
import useCheckListOverviewReports from './useChecklistOverviewReport';
import useCheckListStandartReports from './useCheckListStandartReports';
import useCheckListPrintReports from './useCheckListPrintReports';
import { DEMAND_PROJECT_REPORT_TYPES } from './useDemandProjectReports';
import useDemandProjectReports from './useDemandProjectReports';
import useSearchReport from './useSearchReport';
import useActionPlanReports from './useActionplanReport';
import { loadQuestinaireTemplatesAsync } from "@zert-packages/actions/checklistActions";
import useDetailedXLSReports from "./useDetailedXLSReports";

export default function useReports({ selectedNode, selectedValues, catalogIndex }) {
  const catalog = useSelector((state) => state.catalog);
  const isAdmin = useSelector((state) => state.myuser.role === 'administrator');
  const [ questionaireTemplates, setQuestionaireTemplates ] = useState(null);
  const catalogSelected = catalogIndex >= 0 ? catalog[catalogIndex] : null;
  const dispatch = useDispatch();
  const baseArguments = {
    selectedNode,
    selectedValues,
    catalog: catalogSelected
  };

  const riskOverviewReport = useRiskAnalisisReport({
    ...baseArguments,
    id: RA_REPORT_TYPES.OVERVIEW_REPORT,
  });

  useEffect(() => {
    dispatch(loadRiskAssesmentTemplates());
  }, []);

  useEffect(() => {
    loadQuestinaireTemplatesAsync(false, false)
      .then(v => {
        setQuestionaireTemplates(v);
      });
  }, []);

  const deviationReport = useDeviationReport(baseArguments);
  const checklistOverviewReport = useCheckListOverviewReports(baseArguments);
  const checkListStandardReport = useCheckListStandartReports(baseArguments);
  const checkListPrintReport = useCheckListPrintReports(baseArguments);
  const riskAssessmentReport = useRiskAnalisisReport({
    ...baseArguments,
    id: RA_REPORT_TYPES.REPORT,
  });
  const raOpenMeasuresReport = useRiskAnalisisReport({
    ...baseArguments,
    id: RA_REPORT_TYPES.OPEN_MEASURES,
  });
  const raMeasurementsReport = useRiskAnalisisReport({
    ...baseArguments,
    id: RA_REPORT_TYPES.MEASUREMENTS_REPORT
  });
  const raTemplateReport = useRiskAnalisisReport({
    ...baseArguments,
    id: RA_REPORT_TYPES.TEMPLATE_REPORT
  });
  const raCustomReport = useRiskAnalisisReport({
    ...baseArguments,
    id: RA_REPORT_TYPES.CUSTOM_REPORT
  });

  const raParticipantsReport = useRiskAnalisisReport({
    ...baseArguments,
    id: RA_REPORT_TYPES.PARTICIPANTS_REPORT
  });

  const demandProjectTreeReport = useDemandProjectReports({
    ...baseArguments,
    id: DEMAND_PROJECT_REPORT_TYPES.TREE
  });
  const demandProjectDetailsReport = useDemandProjectReports({
    ...baseArguments,
    id: DEMAND_PROJECT_REPORT_TYPES.DETAILS
  });
  const demandProjectRemainingReport = useDemandProjectReports({
    ...baseArguments,
    id: DEMAND_PROJECT_REPORT_TYPES.REMAINING_THINGS
  });
  const demandProjectCatalogReport = useDemandProjectReports({
    ...baseArguments,
    id: DEMAND_PROJECT_REPORT_TYPES.CATALOG
  });

  const safetyInspection = useSafetyInspectionReport({...baseArguments, questionaireTemplates});
  const measureMentsReport = useMeasureReport(baseArguments);
  const detailedRaXLSReport = useDetailedXLSReports({...baseArguments, id: 0});
  const risksXLSReports = useSummaryXLSReports({
    ...baseArguments,
    id: XLS_REPORT_TYPES.RISK_ASSESSMENT });
  const checklistsXLSReports = useSummaryXLSReports({
    ...baseArguments,
    id: XLS_REPORT_TYPES.CHECKLIST });
  const submitsXLSReports = useSummaryXLSReports({
    ...baseArguments,
    id: XLS_REPORT_TYPES.SUBMITS });
  const measureXLSReports = useSummaryXLSReports({
    ...baseArguments,
    id: XLS_REPORT_TYPES.MEASURES });

 /* const riskAnaliseReports =
    rrtemplates && rrtemplates.length > 0
      ? [riskReports, riskReports1, riskReports2, riskReports3, riskReports4, riskReports5, riskReports6]
      : [riskReports, riskReports1, riskReports2, riskReports3, riskReports4, riskReports6];*/
  const reportsFromPlugin = getPlugins()
    .filter((plugin) => plugin.reportHook)
    .reduce(
      (accum, plugin) => [...accum, ...plugin.reportHook(baseArguments)],
      []
    );


  const isCatalog = catalogIndex != null && catalogIndex >= 0;
  if (isCatalog) {
    const reports = [
      { ...riskAssessmentReport, category:'working'},
      { ...raOpenMeasuresReport, category: 'working' },
      { ...raMeasurementsReport, category: 'working' },
      { ...raTemplateReport, category: 'working' },
      { ...raCustomReport, category: 'working' },
      { ...deviationReport, category: 'summary' },
      { ...safetyInspection, category: 'summary' },
      { ...riskOverviewReport, category: 'summary' },
      { ...measureMentsReport, category: 'summary' },
      { ...demandProjectCatalogReport, category: 'other' },
      { ...checklistOverviewReport, category: 'summary' },
      { ...risksXLSReports, category: 'export' },
      { ...checklistsXLSReports, category: 'export' },
      { ...submitsXLSReports, category: 'export' },
      { ...measureXLSReports, category: 'export' },
      ...(Array.isArray(reportsFromPlugin)
        ? reportsFromPlugin.map((reportFromPlugin) => {
          return { ...reportFromPlugin, category: 'other' };
        })
        : [{ ...reportsFromPlugin, category: 'other' }])
    ];
    if (isAdmin) {
      reports.push({ ...detailedRaXLSReport, category: 'export'})
    }
    return { reports };
  }

  const reports = [];

  if (document.location.pathname === '/action-plan') {
    const apReport = useActionPlanReports(baseArguments);
    reports.push({ ...apReport, category: 'summary' });
  }
  if (document.location.pathname === '/search') {
    const seachReport = useSearchReport(baseArguments);
    reports.push({ ...seachReport, category: 'summary' });
  }
  if (selectedValues.length === 1 &&
      selectedValues[0]?.mimeType === 'application/zert-projectplanning') {
    reports.push({ ...demandProjectTreeReport, category: 'working' });
    reports.push({ ...demandProjectDetailsReport, category: 'working' });
    reports.push({ ...demandProjectRemainingReport, category: 'working' });
  }
  if (selectedValues.find((value) => value.mimeType === 'application/zert-checklist')) {
    reports.push({ ...checkListStandardReport, category: 'working' });
    reports.push({ ...checkListPrintReport, category: 'working'});
    reports.push({ ...checklistsXLSReports, category: 'export' });
  }
  if (selectedValues.find((value) => value.mimeType === 'application/zert-questionnaire-template')) {
    reports.push({ ...checkListPrintReport, category: 'working'});
  }
  if (selectedValues.find((value) => value.mimeType === 'application/zert-risk-analysis')) {
    reports.push(
      { ...riskAssessmentReport, category:'working'},
      { ...raOpenMeasuresReport, category: 'working' },
      { ...raMeasurementsReport, category: 'working' },
      { ...raTemplateReport, category: 'working' },
      { ...raParticipantsReport, category: 'working'},
      { ...raCustomReport, category: 'working' },
    );
    reports.push({ ...risksXLSReports, category: 'export' });
    if (isAdmin) {
      reports.push({ ...detailedRaXLSReport, category: 'export'})
    }
  }
  if (selectedValues.find((value) => value.mimeType === 'application/zert-deviation')) {
    reports.push({ ...deviationReport, category: 'summary' });
    reports.push({ ...submitsXLSReports, category: 'export' });
  }
  if (selectedValues.find((value) => value.mimeType === 'application/zert-safety-inspection')) {
    reports.push({ ...safetyInspection, category: 'summary' });
    reports.push({ ...checkListStandardReport, category: 'working' });
    reports.push({ ...checkListPrintReport, category: 'working'});
  }
  if (selectedValues.find((value) => value.mimeType === 'application/zert-measure')) {
    reports.push({ ...measureXLSReports, category: 'export' });
  }
  return { reports };
}
