import React from 'react'
import {getPluginList} from "../API";
import TemplatesOverview from "@zert-packages/components/TemplatesOverview/TemplatesOverview";
import createListItem from "../helpers/createListItem ";


const LayoutList = () => {
    const fetchLayoutList = () => {
        return getPluginList('layoutedit')
    }

    const addListItem = async (name) => {
        return  createListItem('layoutedit', name)
    }



    return (<div className="PersonalSettings__MainContainer">
        <TemplatesOverview
            templateName={'Layout'}
            fetchTemplates={fetchLayoutList}
            initTemplate={addListItem}
        />
    </div>)
}

export default LayoutList