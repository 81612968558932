import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Structure from "./Structure";
// import MettaData from "@zert-packages/components/InfoElement/mettaData";
import PartOf from "./PartOf";


const useStyles = makeStyles((theme) => ({
  rootLeftExpanded: {
    overflowY: 'auto',
    height: 'calc(100% - 80px)',
    boxShadow: '-5px 5px 5px rgb(0 0 0 / 5%), 5px 5px 5px rgb(0 0 0 / 10%)',
    background: "#FFFFFD",
    borderRadius: 5,
    display: "flex",
    flexDirection: "column",
    transition: 'min-width 0.4s ease-in-out',
    willChange: 'min-width',
    transformOrigin: 'left',
    "& .MuiPaper-elevation1": {
      boxShadow: "none"
    }
  }
}));

//     flex: 0 1 auto;
const LeftExpanded = () => {
  const cl = useStyles();
  const [expandedPanel, setExpandedPanel] = useState({
    expandedPanelOne: true,
    expandedPanelTwo: true,
    expandedPanelThree: true
  });
  return (

        <div className={cl.rootLeftExpanded}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <Structure
                  expanded={expandedPanel}
                  setExpanded={setExpandedPanel}
              />
              <PartOf
                  expanded={expandedPanel}
                  setExpanded={setExpandedPanel}
              />
                {/*<div style={{height: 300, background: 'red', flex: '0 1 auto'}}></div>*/}
                {/*<div style={{*/}
                {/*    height: 300,*/}
                {/*    display: 'flex',*/}
                {/*    alignItems: 'end',*/}
                {/*    background: 'yellow', flex: '0 1 auto',*/}
                {/*    flexGrow: 1*/}
                {/*}}>111*/}
                {/*</div>*/}

        </div>
      </div>


  );
};
export default LeftExpanded;


{/*<Structure*/}
{/*  expanded={expandedPanel}*/}
{/*  setExpanded={setExpandedPanel}*/
}
{/*/>*/
}
{/*<PartOf*/
}
{/*  expanded={expandedPanel}*/
}
{/*  setExpanded={setExpandedPanel}*/}
{/*/>*/}
{/*<MettaData*/}
{/*  expanded={expandedPanel}*/}
{/*  setExpanded={setExpandedPanel}*/}
{/*  context={LayoutContext}*/}
{/*/>*/}
