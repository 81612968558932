import SidePanel from '@zert-packages/components/InfoElement/SidePanel';
import getParameterAsInt from '@zert-packages/utils/getParameterAsInt';
import React, {useEffect, useState, createContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getVersionInfo} from '@zert-packages/actions/coreReducers';
import get from 'lodash/get';
import makeStyles from '@mui/styles/makeStyles';
import {fetchLayout} from './serviceMiddlewares';
import SettingsTab from './SettingsTab';
import {turnOnCLMLoader} from '../TranslationJobPlugin/tjReducers';
import LayoutWrapper from './LayoutWrapper';
import LeftExpanded from "./components/LeftExpanded";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'grid',
        gridTemplateColumns: '2.5fr 5fr',
        gridColumnGap: 20,
        marginTop: 10,
        height: 'Calc(100vh - 50px)',
    },
    title: {
        marginBottom: 5
    }
}));

const layoutData = {
    indexSettings: -1,
    blockClickCreateItemsBlock: false,
};

export const LayoutContext = createContext(null);

function LayoutView(props) {
    const cl = useStyles();
    const CLM = useSelector((state) => state.CLM);
    const isLoading = useSelector((state) => state.CLM.isLoading);
    const {
        match: {params}
    } = props;
    const versionId = getParameterAsInt(params.versionId);
    const dispatch = useDispatch();
    const routeVersionId = get(props, 'match.params.versionId', null);
    const [values, setValues] = useState(null);

    useEffect(() => {
        if (!values && CLM.layout) {
            setValues({...CLM.layout, ...layoutData});
        }
    }, [CLM.layout]);

    useEffect(() => {
        if (routeVersionId === null) return;
        dispatch(getVersionInfo(routeVersionId));
        dispatch(turnOnCLMLoader());
        dispatch(fetchLayout(versionId));
    }, [routeVersionId, versionId]);

    return (
        <LayoutContext.Provider value={{values, setValues}}>
            <LayoutWrapper>
                {/*{values && <div className={cl.root}>*/}
                {/*    <SidePanel*/}
                {/*        id="LayoutLeftSidePanel"*/}
                {/*        panels={['structure', 'variables', 'comments']}*/}
                {/*        context={LayoutContext}*/}
                {/*    />*/}
                {/*    <div><SettingsTab/></div>*/}
                {/*</div>}*/}
                <div className={cl.root}>
                    <LeftExpanded/>
                    <div>
                            {/*<SidePanel*/}
                            {/*    id="LayoutLeftSidePanel"*/}
                            {/*    panels={['structure', 'variables', 'comments']}*/}
                            {/*    context={LayoutContext}*/}
                            {/*/>*/}
                        {values && <SettingsTab/>}
                    </div>
                </div>
            </LayoutWrapper>
        </LayoutContext.Provider>
    );
}

export default LayoutView;
