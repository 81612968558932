import React from 'react'
import {getPluginList} from "../API";
import TemplatesOverview from "@zert-packages/components/TemplatesOverview/TemplatesOverview";
import createListItem from "../helpers/createListItem ";


const StylesheetList = () => {

    const fetchLayoutList =  () => {
        return getPluginList('stylesheet')
    }

    const addListItem =  async (name) => {
        return createListItem('stylesheet', name)
    }


    return(<div className="PersonalSettings__MainContainer">
        <TemplatesOverview
            templateName={'StylesheetList'}
            fetchTemplates={fetchLayoutList}
            initTemplate={addListItem}
        />
    </div>)
}

export default StylesheetList