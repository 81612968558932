import {handlePostToApi, handleQuery} from "@zert-packages/actions/api";

export function getMetaDataApi(versionId, locale) {
    return handleQuery(`/metadata/load/${versionId}/${locale}`);
}


export const storeMetaData = (versionId, data) => {
    return handlePostToApi(`/metadata/store/${versionId}`, data);
}
