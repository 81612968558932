import React, { memo } from 'react';
import { TextField } from '@mui/material';
import addDefaultLanguages from '@zert-packages/utils/addDefaultLanguages';
import withLanguageSwitch from '@zert-packages/utils/withLanguageSwitch';
import getDefaultLabel from '@zert-packages/utils/getDefaultLabel';
import selectiveMemoComparison from '@zert-packages/utils/selectiveMemoComparison';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    '& .MuiFormControl-root': {
      width: '100%'
    }
  }
}));

function TextFieldWithLanguageSwitch({
  state: initialState = getDefaultLabel(),
  onChange,
  languageSwitcher,
  indexOfTranslation,
  findIndexOfLanguages,
  style,
  label,
  ...other
}) {
  const state = addDefaultLanguages(initialState);
  findIndexOfLanguages(state.locales);

  const handleOnChange = (event) => {
    const updatedTranslations = [...state.translations];
    updatedTranslations[indexOfTranslation] = event.target.value;
    const updatedState = { ...state, translations: updatedTranslations };
    onChange(updatedState);
  };

  const cl = useStyles();
  const initialStyle = {
    display: 'grid',
    gridTemplateColumns: '50px 1fr',
    alignItems: 'center',
    width: '100%'
  };

  return (
    <div style={{ ...initialStyle, ...style }} className={cl.wrapper}>
      {languageSwitcher}
      <TextField
        margin="dense"
        variant="outlined"
        value={state.translations[indexOfTranslation]}
        onChange={handleOnChange}
        label={label}
        {...other}
      />
    </div>
  );
}

export default withLanguageSwitch(TextFieldWithLanguageSwitch);

export const TextFieldWithLanguageSwitchMemo = memo(
  withLanguageSwitch(TextFieldWithLanguageSwitch),
  selectiveMemoComparison(
    (p) => p.disabled,
    (p) => p.state,
    (p) => p.disabled
  )
);
