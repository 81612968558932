import React, { useEffect, useState, createContext } from 'react';
import { Tabs, Tab } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import BlockTabs from './ExpanderItem/blockTabs/BlockTabs';
import { getApiStylesheet } from './API';
import { gridBlock } from './utils/helpers';
import useStyles from './styles';
import Table from './ExpanderItem/table/Table';
import SkeletonLoading from './SkeletonLoading';
import { findLeftMenu } from './utils/findLeftMenu';
import Loading from './Loading';
import Image from './ExpanderItem/image/Image';
import Part from './ExpanderItem/part/Part';
import Paragraph from './ExpanderItem/paragraph/Paragraph';
import Text from './ExpanderItem/text/Text';
import IndexTab from './ExpanderItem/indexTab/IndexTab';
import Include from './ExpanderItem/include/Include';
import References from './ExpanderItem/references/References';
import Hyphenation from './ExpanderItem/hyphenation/Hyphenation';
import ContentFilters from './ExpanderItem/contentFilters/ContentFilters';
import ElementFilters from './ExpanderItem/elementFilters/ElementFilters';

export const TabsContext = createContext(null);

function Stylesheet() {
  const cl = useStyles();
  const [expander, setExpander] = useState(2);
  const [values, setValues] = useState({
    tabs: [
      'Block',
      'Table',
      'Image',
      'Part',
      'Paragraph',
      'Text',
      'Index',
      'References',
      'Include',
      'Fonts',
      'Hyphenation',
      'Content filters',
      'elementFilters'
    ],
    selectedTab: 0,
    leftMenu: null,
    selectedLeftMenu: 0,
    stylesheetTabs: null,
    addReferences: null,
    stylePageLayout: {
      width: 105,
      height: 148.5,
      paddingRight: 10,
      paddingLeft: 10,
      paddingTop: 10,
      paddingBottom: 10
    },
    stylesheet: null,
    selectedDropMenu: null,
    selectedTabName: null
  });
  const { formatTemplate, mylocale } = useSelector((state) => state);
  const { versionId } = useParams();

  const handleExpander = (arrow) => {
    if (arrow === 'left') {
      if (expander === 2) {
        setExpander(1);
      }
      if (expander === 3) {
        setExpander(2);
      }
    } else {
      if (expander === 1) {
        setExpander(2);
      }

      if (expander === 2) {
        setExpander(3);
      }
    }
  };

  const handleChangeSelectedTab = (event, newValue) => {
    setValues((prev) => ({
      ...prev,
      selectedLeftMenu: 0,
      selectedTab: newValue
    }));
  };

  const handleStylesheet = (name, value) => {
    setValues((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSelectedListItem = (el, idx) => {
    if (values.selectedLeftMenu === idx) {
      return;
    }
    handleStylesheet('selectedLeftMenu', idx);
  };

  const findReferences = () => {
    const referencesFind = Object.values(formatTemplate)
      .filter((el) => el && el.length > 0 && Array.isArray(el))
      .flat()
      .filter((el) => el.references)
      .map((el) => el.references)
      .flat()
      .sort((a, b) => a.type.localeCompare(b.type));
    const result = referencesFind.reduce((acu, cur) => {
      if (!acu.find((item) => item.identifier === cur.identifier)) {
        return [...acu, cur];
      }
      return acu;
    }, []);
    if (result) {
      setValues((prev) => ({ ...prev, addReferences: result }));
    }
  };

  const getStylesheet = async () => {
    const result = await getApiStylesheet(versionId, mylocale);
    if (result) {
      handleStylesheet('stylesheet', result);
    }
  };

  useEffect(() => {
    if (formatTemplate && !values.addReferences) {
      findReferences();
    }
  }, [values.addReferences, formatTemplate]);

  useEffect(() => {
    if (values.stylesheet) {
      findLeftMenu(values.selectedTab, values.stylesheet, setValues);
    }
  }, [values.selectedTab, values.stylesheet]);

  useEffect(() => {
    getStylesheet();
  }, []);



  return (
    <div className={cl.root}>
      {values.tabs && (
        <Tabs
          indicatorColor=""
          className={cl.tabsRoot}
          value={values.selectedTab}
          onChange={handleChangeSelectedTab}
          textColor="primary"
          variant="standard"
          scrollButtons="auto"
          centered
        >
          {values.tabs.map((el, i) => (
            <Tab label={el} key={i} />
          ))}
        </Tabs>
      )}
      <TabsContext.Provider value={{ values, setValues }}>
        {values.selectedTab < 6 ? (
          <div className={cl.wrapperRoot} style={{ gridTemplateColumns: gridBlock(expander) }}>
            <div
              style={{
                position: 'relative',
                height: 'Calc(100% - 47px)'
              }}
            >
              <div className={cl.wrapperLeftMenu}>
                {expander !== 1 && (
                  <div style={{ height: '100%' }}>
                    {values.leftMenu ? (
                      values.leftMenu.map((el, i) => (
                        <div
                          onClick={() => handleSelectedListItem(el, i)}
                          className={values.selectedLeftMenu === i ? cl.listLeftMenuSelected : cl.listLeftMenu}
                          key={i}
                        >
                          {el.label}
                        </div>
                      ))
                    ) : (
                      <SkeletonLoading />
                    )}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div
                className={cl.wrapperArrow}
                style={{
                  marginLeft: expander === 1 ? -5 : -9
                }}
              >
                <span>{expander !== 1 && <ArrowLeftIcon onClick={(e) => handleExpander('left')} />}</span>
                {expander !== 3 && <ArrowRightIcon onClick={(e) => handleExpander('right')} />}
              </div>
            </div>
            <div
              className={cl.wrapperRightBlock}
              style={{
                display: expander === 3 ? 'none' : 'block'
              }}
            >
              {values.stylesheet ? (
                <>
                  {values.selectedTab === 0 && <BlockTabs />}
                  {values.selectedTab === 1 && <Table />}
                  {values.selectedTab === 2 && <Image />}
                  {values.selectedTab === 3 && <Part />}
                  {values.selectedTab === 4 && <Paragraph />}
                  {values.selectedTab === 5 && <Text />}
                </>
              ) : (
                <Loading />
              )}
            </div>
          </div>
        ) : (
          <>
            {values.selectedTab === 6 && <IndexTab />}
            {values.selectedTab === 7 && <References />}
            {values.selectedTab === 8 && <Include />}
            {values.selectedTab === 10 && <Hyphenation />}
            {values.selectedTab === 11 && <ContentFilters />}
            {values.selectedTab === 12 && <ElementFilters />}
          </>
        )}
      </TabsContext.Provider>
    </div>
  );
}

export default Stylesheet;
