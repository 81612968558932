import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import handleValueState from '@zert-packages/utils/handleValueState';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import GroupDialog from './dialog/GroupDialog';

const useStyles = makeStyles((theme) => ({
  group: (props) => ({
    color: props.select ? '#01A1C7' : 'inherit',
    cursor: props.blockClick ? 'not-allowed' : 'pointer',
    '&:hover': {
      textDecoration: props.blockClick ? '' : 'underline'
    }
  })
}));

function FormatBlock({ select, blockClick, block, setBlock, parentItemCreate }) {
  const cl = useStyles({ select, blockClick });
  const [format, setFormat] = useState({
    selectFormat: null,
    formats: null
  });
  const el = block?.blockItem;
  const info = el?.elementInfo || el?.info;
  const getFormat = info?.properties?.['zert:Format'];

  const openGroupDialog = (e) => {
    if (select) {
      e.stopPropagation();
    }
    if (blockClick || info?.approvedAt) {
      return;
    }
    renderInExceptionRoot(GroupDialog, { format, setBlock, el, parentItemCreate, block });
  };

  useEffect(() => {
    if (getFormat) {
      handleValueState(getFormat, 'selectFormat', setFormat, {
        selectFormat: getFormat,
        formats: [getFormat]
      });
    }
  }, [el]);

  return (
    <div
      style={{
        opacity: info?.approvedAt ? 0.5 : ''
      }}
      className={cl.group}
      onClick={(e) => openGroupDialog(e)}
    >
      {format?.selectFormat}
    </div>
  );
}

export default FormatBlock;
