import React from 'react'
import makeStyles from "@mui/styles/makeStyles";
import {AccordionSummary, Checkbox, FormControlLabel} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import Tree from "@zert-packages/components/InfoElement/Structure/Tree";


const useStyles = makeStyles((theme) => ({
  rootExpanderElementProperties: {
    flexGrow: 1
  },
  rootAccordion: {
    flex: '0 1 auto',
    borderBottomLeftRadius: "0px !important",
    borderBottomRightRadius: "0px !important",
    borderBottom: "1px solid #E2F2F6",
    height: "100%",
    "& .MuiCollapse-wrapper, .MuiAccordionDetails-root, #panel1a-content": {
      height: "100%"
    },
    "& .MuiCollapse-wrapperInner": {
      height: "100%"
    }
  },
  dropMenuButton: {
    width: "100%",
    justifyContent: "space-between",
    border: "1px solid #DCE3E5",
    marginBottom: 15,
    color: "#8B8B8B",
    fontWeight: "400",
    paddingBottom: 2,
    paddingTop: 2,
    background: "#ffffff",
    paddingRight: 15,
    borderRadius: 0,
    outline: "none !important",
    "&:hover": {
      background: "#ffffff"
    },
    "& span:nth-child(2)": {
      display: "none"
    }
  },
  menuLinkSiteWrapper: {
    borderRadius: 0,
    // width: 470,
    width: "fit-content",
    minWidth: 315,
    maxWidth: "Calc(100% - 40px)",
    marginTop: 40,
    border: "1px solid #DCE3E5",
    boxShadow: "none",
    "@media (max-width: 900px)": {
      marginTop: 45,
      width: "Calc(100% - 151px)"
    }
  },
  AccordionDetailsWrapper: {
    height: "100%",
    padding: "8px 0px !important",
    overflow: "auto"
  },
  rootAccordionDetails: {
    width: "100%",
    height: "100%",
    padding: "0px 16px"
  },
}));

const PartOf = ({ expanded, setExpanded }) => {
  const cl = useStyles();

  const handleChange = () => {
    setExpanded({ ...expanded, expandedPanelTwo: !expanded.expandedPanelTwo });
  };

  return (<div className={expanded.expandedPanelTwo ? cl.rootExpanderElementProperties : ""}>
    <Accordion
      expanded={expanded.expandedPanelTwo}
      onChange={() => handleChange("panel2")}
      style={{
        height: expanded.expandedPanelTwo ? "Calc(100% - 46px) !important" : ""
      }}
      className={cl.rootAccordion}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        Part Of
      </AccordionSummary>
      <AccordionDetails className={cl.AccordionDetailsWrapper}>
        <div className={cl.rootAccordionDetails}>
          <Tree
            structureMain={true}
          />
          <div>

            <FormControlLabel
              control={
                <Checkbox
                />
              }
              label={<span> Show all versions </span>}
            />
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  </div>);
}

export default PartOf