import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles({
  root: {
    fontSize: 18,
    letterSpacing: 1,
    color: '#333',
    margin: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  }
});
function NoItemsInList() {
  return (
    <div className={useStyles().root}>
      <FormattedMessage id="NoItemsInList" defaultMessage="Look like there're no items in list" />
    </div>
  );
}

export default NoItemsInList;
