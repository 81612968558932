import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, IconButton, IconButtonProps } from '@mui/material';
import { Add, Cancel, Delete, Edit, Save, Check, NavigateNext, NavigateBefore } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
  successRoot: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark
    }
  },
  errorRoot: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  }
}));

export function SaveButton(props: ButtonProps) {
  return (
    <Button startIcon={<Save />} {...props}>
      {props.children ? props.children : <FormattedMessage id="CustomButtons.saveButton" defaultMessage="Save" />}
    </Button>
  );
}

export function CancelButton(props: ButtonProps) {
  return (
    <Button variant="text" startIcon={<Cancel />} {...props}>
      {props.children ? props.children : <FormattedMessage id="CustomButtons.cancelButton" defaultMessage="Cancel" />}
    </Button>
  );
}

export function NextButton(props: ButtonProps) {
  return (
    <Button endIcon={<NavigateNext />} {...props}>
      {props.children ? props.children : <FormattedMessage id="CustomButtons.nextButton" defaultMessage="Next" />}
    </Button>
  );
}

export function BackButton(props: ButtonProps) {
  return (
    <Button variant="text" startIcon={<NavigateBefore />} {...props}>
      {props.children ? props.children : <FormattedMessage id="CustomButtons.backButton" defaultMessage="Back" />}
    </Button>
  );
}

export function AddButton(props: ButtonProps) {
  const cl = useStyles();

  return (
    <Button classes={{ root: cl.successRoot }} startIcon={<Add />} {...props}>
      {props.children ? props.children : <FormattedMessage id="CustomButtons.AddButton" defaultMessage="Add" />}
    </Button>
  );
}

export function EditButton(props: ButtonProps) {
  return (
    <Button variant="outlined" startIcon={<Edit />} {...props}>
      {props.children ? props.children : <FormattedMessage id="CustomButtons.editButton" defaultMessage="Edit" />}
    </Button>
  );
}

export function DeleteButton(props: ButtonProps) {
  const cl = useStyles();

  return (
    <Button classes={{ root: cl.errorRoot }} startIcon={<Delete />} {...props}>
      {props.children ? props.children : <FormattedMessage id="CustomButtons.deleteButton" defaultMessage="Delete" />}
    </Button>
  );
}

export function ApproveButton(props: ButtonProps) {
  const cl = useStyles();

  return (
    <Button classes={{ root: cl.successRoot }} startIcon={<Check />} {...props}>
      {props.children ? props.children : <FormattedMessage id="CustomButtons.Approve" defaultMessage="Approve" />}
    </Button>
  );
}

export function ErrorButton(props: ButtonProps) {
  const cl = useStyles();

  return (
    <Button classes={{ root: cl.errorRoot }} {...props}>
      {props.children}
    </Button>
  );
}

export function PrimaryButton(props: ButtonProps & { isLoading?: boolean }) {
  return (
    <Button disabled={props.isLoading} {...props}>
      {props.isLoading ? <CircularProgress size={20} /> : props.children}
    </Button>
  );
}

export function SecondaryButton(props: ButtonProps) {
  return (
    <Button color="secondary" {...props}>
      {props.children}
    </Button>
  );
}

export function SuccessButton(props: ButtonProps) {
  const cl = useStyles();

  return (
    <Button classes={{ root: cl.successRoot }} {...props}>
      {props.children}
    </Button>
  );
}

type ExpandCollapseIconButtonProps = IconButtonProps & {
  expanded: boolean;
  onClick: () => void;
};

export function ExpandCollapseIconButton({ expanded, onClick, ...restProps }: ExpandCollapseIconButtonProps) {
  return (
    <IconButton onClick={onClick} aria-expanded={expanded} {...restProps}>
      <ExpandMoreIcon
        sx={{
          transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: 'transform 0.3s'
        }}
      />
    </IconButton>
  );
}
