import React, { createContext, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import TextFieldItem from '@zert-packages/components/TextFieldItem';
import ResizedTable from './ResizedTable';
import { getTableNewApi } from '../../PhrasePlugin/API';
import { handleSetData } from './helpers/tableMethods';
import TableFormat from './components/dialog/AddToolbarDialog/TableFormat';

const useStyles = makeStyles((theme) => ({
  root: {},
  wrapperSettings: {
    display: 'grid',
    gridRowGap: 10
  },
  wrapperLabel: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridColumnGap: 10
  },
  label: {
    fontWeight: '700'
  }
}));

export const TableContext = createContext(null);

function CustomTable({
  versionId,
  table,
  vue,
  actionTypeProps,
  setTable,
  setName,
  createRoot,
  createBlock,
  setCreateBlock,
  insertedBlock
}) {
  const cl = useStyles();
  const [schema, setSchema] = useState({ columns: 0, rows: 0 });
  const [tableData, setTableData] = useState(null);
  const [values, setValues] = useState({
    copyCell: null,
    tableDefault: null,
    keepWith: [],
    treeItem: null,
    phraseEditor: {
      editorState: null,
      contentStateJSON: null,
      maxLength: null,
      phrase: ''
    }
  });
  const { myLanguage } = useSelector((state) => state);
  const { tableDefault } = values;
  const [load, setLoad] = useState(false);

  const handleData = async () => {
    if (table) {
      return handleSetData(table, setSchema, setTableData, setValues);
    }
    if (versionId) {
      setLoad(true);
      // const tableApiResultOld = await getTableApi(versionId,myLanguage.value);
      const tableApiResultNew = await getTableNewApi(versionId, myLanguage.value);
      if (tableApiResultNew) {
        // const data = {...tableApiResultNew, columnCount: 1, rowCount: 1}
        handleSetData(tableApiResultNew, setSchema, setTableData, setValues);
      }
      setLoad(false);
    }
  };

  const handleName = (val) => {
    const res = {
      ...tableDefault,
      name: val,
      elementInfo: {
        ...tableDefault.elementInfo,
        name: val
      }
    };
    if (setName) {
      setName(val);
    }
    setValues((pre) => ({ ...pre, tableDefault: res }));
  };

  useEffect(() => {
    if (myLanguage && !load) {
      handleData();
    }
  }, [myLanguage, table]);

  useEffect(() => {
    if (tableDefault && setTable) {
      setTable(tableDefault);
    }
  }, [tableDefault]);

  return (
    <TableContext.Provider value={{ values, setValues, block: values, setBlock: setValues }}>
      <div className={cl.root}>
        {createRoot && (
          <div className={cl.wrapperSettings}>
            <div className={cl.wrapperLabel}>
              <div className={cl.label}>Name</div>
              <TextFieldItem
                error={tableDefault?.name.length === 0}
                values={tableDefault?.name || ''}
                setValues={handleName}
              />
            </div>
            <div className={cl.wrapperLabel}>
              <div className={cl.label}>Format</div>
              <TableFormat createBlock={createBlock} setCreateBlock={setCreateBlock} />
            </div>
          </div>
        )}
        {tableData && (
          <ResizedTable
            values={values}
            setValues={setValues}
            vue={vue}
            data={tableData}
            schemaGlob={schema}
            actionTypeProps={actionTypeProps}
            insertedBlock={insertedBlock}
          />
        )}
      </div>
    </TableContext.Provider>
  );
}

export default CustomTable;
