import React, { useEffect, useState } from "react";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { SelectProps } from '@mui/material/Select';
import { alpha } from '@mui/material/styles';
import { FormattedMessage } from "react-intl";

export default function MultipleSelectNative(
    {
      propName = 'name',
      propId = 'id',
      caption,
      value,
      options,
      takeValue=false,
      useAll=false,
      allKey='all',
      allLabel= <FormattedMessage
        id={'MultipleSelectNative.allLabel'}
        defaultMessage={'Select all'}
      />,
      useNone=false,
      noneKey='none',
      noneLabel=<FormattedMessage
        id={'MultipleSelectNative.noneLabel'}
        defaultMessage={'Select none'}
      />,
      onStateChanged,
      sx=null
    }: {
      caption: string;
      propName?: string;
      propId?: string;
      value: any;
      options: any;
      takeValue?: boolean;
      useAll?: boolean;
      allKey?: string;
      allLabel?: React.ReactElement | string;
      useNone?: boolean;
      noneKey?: string;
      noneLabel?: React.ReactElement | string;
      onStateChanged: ((value: any) => any);
      sx?: SelectProps['sx'];
    }) {

  const [selected, setSelected] = useState<any>([]);
  const [idOptionMap, setIdOptionMap] = useState({});
  const extraOptions: Array<any> = [];
  if (useNone) {
    extraOptions.push({[propName]:noneLabel, [propId]: noneKey});
  }
  if (useAll) {
    extraOptions.push({[propName]:allLabel, [propId]: allKey});
  }
  const extendedOptions = [...extraOptions, ...options];
  const startPos = (useAll ? 1 : 0) + (useNone ? 1 : 0);

  useEffect(() => {
    setSelected(value.map(v => takeValue ? v : v[propId]));
  }, []);

  useEffect(() => {
    setIdOptionMap(Object.fromEntries(
      options.map(o => [takeValue ? o : o[propId], o])
    ));
  }, [takeValue, propId, options]);

  const handleNewState = newValues => {
    setSelected(newValues);
    onStateChanged(newValues.map(v => idOptionMap[v]));
  }

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectOptions = event.target.options;
    if (useNone && selectOptions[0].selected) {
      handleNewState([]);
    } else if (useAll && selectOptions[startPos - 1].selected) {
      handleNewState([...options.map(v => takeValue ? v : v[propId])]);
    } else {
      handleNewState(Array.from(selectOptions)
        .slice(startPos)
        .filter(o => o.selected)
        .map(o => o.value));
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <FormControl style={{ height: '400px' }}>
        <div>
          <InputLabel sx={{ position: 'relative', transform: 'translate(14px, 9px) scale(0.75)' }} shrink htmlFor="select-multiple-native">
            {caption}
          </InputLabel>
        </div>
        <Select
          multiple
          native
          value={selected}
          // @ts-ignore
          onChange={handleChange}
          label="Native"
          autoFocus={false}
          inputProps={{
            sx: 'height: -webkit-fill-available !important',
            id: 'select-multiple-native'
          }}
          variant={'outlined'}
          sx={sx || { height: '-webkit-fill-available',
            "#select-multiple-native option": {
              fontWeight: 'fontWeightRegular',
              color: 'black',
              "&:focus": {
                outline: 'solid'
              }
            },
            "#select-multiple-native option:checked": {
              backgroundColor: theme =>
                `${alpha(theme.palette.primary.dark, 0.12)} !important`,
              fontWeight: 'fontWeightMedium',
              color: 'black',
              "&:focus": {
                outline: 'solid'
              }
            }
          }}
        >
          {extendedOptions.map((option) => (
            <option
              key={option[propName]}
              value={option[propId]}
            >
              {option[propName]}
            </option >
          ))}
        </Select>
      </FormControl>
    </div>
  );
}